import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import "./header.scss";
import cn from "classnames";
import MirchiLogo from "static/images/logo.svg";
import UAEMirchiLogo from "static/images/uae-logo.png";
import Close from "static/images/cross.svg";
import HeaderMenu from "../headerMenu";
import FooterData from "data/menuData.js";
import HeaderData from "data/menuData.js";
import * as contactUsActions from "store/contactUs/actions";
import * as uiActions from "store/ui/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ContactUs from "../../views/contactUs"
class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHeader: true,
      visible: false,
      isActive: false,
      pageYOffset: 0,
      transparent: false,
      headerText: "",
      showInquiryModal: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.checkPath(this.props);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleOpenMenu = () => {
    if (document.body.style.position === "fixed") {
      document.body.style.position = "unset";
    } else {
      document.body.style.position = "fixed";
    }
    this.setState({
      visible: !this.state.visible,
    });
  };

  handleCloseMenu = () => {
    document.body.style.position = "unset";
    this.setState({
      visible: !this.state.visible,
    });
  };

  componentWillReceiveProps(nextProps) {
    this.checkPath(nextProps);
  }

  close = () => {
    document.body.style.position = "static";
    this.props.history.goBack();
  };

  checkPath = (props) => {
    let activeRoute = FooterData.find(
      (data) => data.link === props.location.pathname
    );
    if (activeRoute) {
      this.setState({ headerText: activeRoute.headerName });
    }

    if (props.location.pathname.includes("station")) {
      if (props.location.pathname.split("/station/").length >= 2) {
        this.setState({ showHeader: false });
      } else {
        this.setState({ showHeader: true });
      }
    } else if (props.location.pathname.includes("/rj/")) {
      if (props.location.pathname.split("/rj/").length > 1) {
        this.setState({ showHeader: false });
      } else {
        this.setState({ showHeader: true });
      }
    } else if (props.location.pathname.includes("/show/")) {
      this.setState({ showHeader: false });
    } else if (props.location.pathname.includes("/about-us")) {
      this.setState({ showHeader: false });
    } else if (props.location.pathname.includes("/tune-hd")) {
      this.setState({ showHeader: false });
    } else {
      this.setState({ showHeader: true });
    }
  };

  handleScroll = (event) => {
    this.setState({ pageYOffset: window.pageYOffset });
  };

  render() {
    return (
      <div className="header-container">
        {
          <div
            className={cn(
              "header fixed z-50 w-full py-2 px-5 top-0 left-0 hidden-desktop flex justify-between items-center",
              {
                "bg-white show-shadow":
                  this.props.location.pathname !== "/" ||
                  this.state.pageYOffset >= 300 ||
                  this.props.location.pathname === "/station",
                "no-shadow": this.props.location.pathname === "/mirchi-murga",
                "no-header": !this.state.showHeader,
                "bg-black":
                  this.state.pageYOffset >= 150 &&
                  this.props.location.pathname === "/mirchi-top20",
                "bg-transparent":
                  this.props.location.pathname === "/mirchi-top20" ||
                  this.props.location.pathname === "/mirchi-murga",
                "mirchi-murga-bg":
                  this.props.location.pathname === "/mirchi-murga" &&
                  this.state.pageYOffset >= 150,
              }
            )}
          >
            <div className="flex items-end">
              <div
                className={`hamburger  ${this.state.visible ? "active" : ""}
              ${
                this.state.pageYOffset >= 300 ||
                this.props.location.pathname !== "/"
                  ? "hamburger-fixed"
                  : ""
              }`}
                onClick={() => this.handleOpenMenu()}
              >
                <span />
                <span />
                <span />
              </div>
              {(this.props.location.pathname !== "/" ||
                this.state.pageYOffset >= 300) &&
              this.state.showHeader ? (
                <div
                  className={`ml-2 text-xl text-black font-black leading-tight ${
                    this.props.location.pathname === "/mirchi-top20" &&
                    "opacity-0"
                  } ${
                    this.props.location.pathname === "/mirchi-top20" &&
                    this.state.pageYOffset >= 150 &&
                    "text-white top20-header"
                  }
                  ${
                    this.props.location.pathname === "/mirchi-murga" &&
                    "opacity-0"
                  } ${
                    this.props.location.pathname === "/mirchi-murga" &&
                    this.state.pageYOffset >= 150 &&
                    "text-black opacity-1 top20-header"
                  }`}
                >
                  <div className="flex items-center">
                    <div className="mr-2">{this.state.headerText}</div>
                    {this.state.headerText === "Tune In" && (
                      <div className="live-container px-2 py-1 leading-none rounded-lg text-white font-black text-sm">
                        LIVE
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <Link to={"/"} className="block">
              <img
                className="mirchi-logo"
                src={UAEMirchiLogo}
                alt="radio-mirchi-logo"
              />
            </Link>

            <HeaderMenu
              visible={this.state.visible}
              closeMenu={() => this.handleCloseMenu()}
            />
            <img
              className="close"
              src={Close}
              alt="close"
              onClick={() => this.close()}
            />
          </div>
        }
        {
          <div
            className={cn(
              "header  show-shadow fixed z-50 w-full top-0 bg-white  overflow-hidden left-0 hidden-tablet"
            )}
          >
            <div className="flex justify-between max-width pl-5">
              <Link to={"/"} className="flex items-center">
                <img
                  className="mirchi-logo h-20"
                  src={UAEMirchiLogo}
                  alt="radio-mirchi-logo"
                />
              </Link>

              <div className="header-menu flex justify-between items-center ">
                {HeaderData.slice(1).map((data, i) => {
                  return (
                    <NavLink
                      className="header-text h-full flex items-center text-sm px-3  font-bold"
                      key={i}
                      to={data.link}
                      activeClassName="active"
                      onClick={() => {
                        this.props.showCountDownModalAction(false);
                      }}
                    >
                      {data.name}
                    </NavLink>
                  );
                })}
                {/* <div
                  className="header-text h-full px-3 cursor-pointer flex items-center text-sm contact-us font-bold"
                  onClick={() => {
                    this.props.showCountDownModalAction(true)
                    this.props.showshowInquiryModalAction(false)
                    // this.setState({showInquiryModal: true})
                  }}
                >
                  Countdowns
                </div> */}
                {/* <NavLink
                      className="header-text h-full flex items-center text-sm px-3  font-bold"
                      to="/about-us"
                      activeClassName="active"
                      onClick={() => {
                      this.props.showCountDownModalAction(false)}}
                    >
                      About Us
                </NavLink> */}

                {/* <div
                  className="header-text h-full cursor-pointer flex items-center text-sm contact-us font-bold"
                  // onClick={() => {
    
                  //   this.props.showshowInquiryModalAction(true);
                  //   this.props.showCountDownModalAction(false);
                  //   // this.setState({showInquiryModal: true})
                  // }}
                >
                 <Link
                  to={`/contact-us`}
                  >
                  Contact Us
                  </Link>  */}
                  {/* Contact Us */}
                  {/* <div style={{paddingLeft:"50px"}}>
                    <img src={"https://mmo.aiircdn.com/265/6028bece40736.png"} width={"50px"}/>
                  </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default connect(
  ({ contactUs, ui }) => ({ ...contactUs, ...ui }),
  (dispatch) =>
    bindActionCreators({ ...contactUsActions, ...uiActions }, dispatch)
)(header);
