import { initialState } from "./state";

export const podcast = (state = initialState, action) => {
  switch (action.type) {
    
    case "SET_PODCAST_LIST": {
      return Object.assign({}, state, {
        podcastList: action.data
      });
    }
    case "SET_ACTIVE_PODCAST": {
      return Object.assign({}, state, {
        activePodcast: action.data
      });
    }
    default:
      return state;
  }
};
