import axios from "axios";
import { WP_URL } from "./apiUrl";


const _authorizationHeaders = () => ({
    
});

const handleError = err => {
    console.log(`Api call error in services -> request.js : `, err);
};

export const getRequest = async (url, headers = _authorizationHeaders()) => {
    try {
        const res = await axios.get(WP_URL + url, {
            headers: Object.assign({}, headers)
        });
        return res.data.result ? res.data.result : res.data;
    } catch (err) {
        handleError(err);
    }
};


export const WP_Api = {
    getRequest
}