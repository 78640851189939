import { mutation } from "./mutations";
import axios from "axios";

export const showLoader = value => async dispatch => {
  dispatch(mutation.isLoading(value));
};

export const showGlobalAudioPlayer = value => async dispatch => {
  dispatch(mutation.showGlobalAudioPlayer(value));
};

export const pauseAudioPlayer = value => async dispatch => {
  dispatch(mutation.PauseAudioPlayer(value));
};

export const pauseOtherVideos = data => async dispatch => {
  dispatch(mutation.pauseOtherVideos(data));
};

export const openVideoPlayerModal = (value, data) => async dispatch => {
  const obj = {
  value,data
  };
  dispatch(mutation.openVideoPlayerModal(obj));
};

export const showAboutUsModalAction = value => async dispatch => {
  dispatch(mutation.showAboutUsModal(value));
};
export const showshowInquiryModalAction = value => async dispatch => {
  dispatch(mutation.showInquiryModal(value));
};
export const showCountDownModalAction = value => async dispatch => {
  dispatch(mutation.showCountDownModal(value));
};

export const showFaqModalAction = value => async dispatch => {
  dispatch(mutation.showFaqModal(value));
};

export const setAudioPLayerData = obj => async dispatch => {
  dispatch(mutation.setAudioData(obj));
};

export const hideAudioPlayerData = () => async dispatch => {
  dispatch(mutation.clearAudioData());
};

export const updateWindowWidth = () => async dispatch => {
  try {
    dispatch(mutation.setWindowWidthState(window.innerWidth));
  } catch (err) {
    console.log("error", err);
  }
};

export const checkForUS = () => async dispatch => {
  try {
    axios
      .get("https://ipapi.co/json")
      .then(resp => {
        console.log(resp)
        if (resp.data["country_code"] === "AE") {
          dispatch(mutation.setCountryQA(true));
        } else {
          dispatch(mutation.setCountryQA(false));
        }
      })
      .catch(err => {
        console.log("error", err);
      });
  } catch (err) {
    console.log("error", err);
  }
};
