import { News } from "../../services/news";
import { mutation } from "./mutations";

const handleError = err => {
  console.log("Error in News action : ", err);
};

export const getNewsListRequest = () => async dispatch => {
  try {
    let result = await News.getNewsList();
    dispatch(mutation.setNewsList(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};
export const getNewsDetailRequest = (id) => async dispatch => {
  try {
    let result = await News.getNewsDetail(id);
    dispatch(mutation.setActiveNews(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

