import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as wpActions from "../../store/wp_store/actions";
import * as uiActions from "store/ui/actions";
import "./WPCard.scss";
import { validS3Url } from "../../services/config/request";

class WPCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgUrl: '',
            isLoaded: false
        }
    }
    componentDidMount() {
        // console.log(this.props);
        const { featured_media } = this.props.data;
        if (featured_media && featured_media != 0) {

            const getImageUrl = this.props.getMediaByIdRequest(featured_media);

            Promise.all([getImageUrl]).then(res => {
                this.setState({
                    imgUrl: res[0].media_details.sizes["medium"].source_url,
                    isLoaded: true
                });
            })
        }
        else {
            this.setState({
                imgUrl: "",
                isLoaded: true
            });
        }
    }
    render() {
        const { title, excerpt, slug, categories, date } = this.props.data;
        const { imgUrl, isLoaded } = this.state;
        // console.log(this.props.data);
        let dateConv = new Date(date);
        function month_name(dt) {
            var mlist = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            return mlist[dt];
        };
        var dateMDY = `On ${month_name(dateConv.getMonth() + 1)} ${dateConv.getDate()}, ${dateConv.getFullYear()}`;

        if (isLoaded) {
            return (
                <div className="singleBlog2 lg">
                    <div className="blogImg2">
                        <img src={validS3Url(imgUrl)} alt="" />
                    </div>
                    <div className="blog-about2">
                        <div className="category2">{this.props.type}</div>
                        <div
                            className="title2"
                            dangerouslySetInnerHTML={{
                                __html: title.rendered,
                            }}></div>
                        <div
                            className="description2"
                            dangerouslySetInnerHTML={{
                                __html: excerpt.rendered,
                            }}></div>
                        <div
                            className="description2"
                            dangerouslySetInnerHTML={{
                                __html: dateMDY,
                            }}></div>

                   
                    </div>
                </div>
            );
        }
        return null;
    }
}
export default connect(
    ({ wp, ui }) => ({ ...wp, ...ui }),
    dispatch => bindActionCreators({ ...wpActions, ...uiActions }, dispatch)
)(WPCard);