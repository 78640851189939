import { MirchiMurga } from "../../services/mirchiMurga";
import { mutation } from "./mutations";
import { storage as LocalStorage } from "../../services/config/storage";

const handleError = err => {
  console.log("Error in mirchiTop20 action : ", err);
};

// Actions
export const getMirchiMurgaListRequest = () => async dispatch => {
  try {
    let result = await MirchiMurga.requestMirchiMurgaData();
    dispatch(mutation.setMirchiMurgaList(result));
    LocalStorage.set.mirchiMurga(result);
  } catch (err) {
    handleError(err);
  }
};
