const setGalleryList = data => ({
    type: "SET_GALLERY_LIST",
    data
  });

  const setActiveGallery = data => ({
    type: "SET_ACTIVE_GALLERY",
    data
  });

  const setGalleryImageList = data => ({
    type: "SET_GALLERYIMAGE_LIST",
    data
  });
  
  
  export const mutation = {
    setGalleryList,
    setActiveGallery,
    setGalleryImageList
  };
  