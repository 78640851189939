const setMovieReviewsList = data => ({
    type: "SET_MOVIEREVIEWS_LIST",
    data
  });

  const setActiveMovieReviews = data => ({
    type: "SET_ACTIVE_MOVIEREVIEWS",
    data
  });
  
  
  export const mutation = {
    setMovieReviewsList,
    setActiveMovieReviews
  };
  