import React from "react";
import AppleStore from "static/images/app-store.svg";
import GoogleStore from "static/images/google-play.svg";

const PlayStoreIcons = (props) => {
  return null;
    // return (
    //   <div className={`play-store-icons ${props.tablet && 'mb-16'}`}>
    //     <div className="footer-text text-white mb-2  lg:block">
    //       Experience the Mobile App
    //       </div>
    //     <div className="flex">
    //       <a
    //         href=" https://play.google.com/store/apps/details?id=com.nobexinc.wls_9988260021.rc&fbclid=IwAR1qeaaCgWaBu8Lh1o31CmewRYUFOdHxKKVTZ2P1vdliMnZEOxVAaX5J6Ks"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //         className="relative"
    //       >
    //         <img
    //           src={GoogleStore}
    //           alt="google-store"
    //           className="mr-5"
    //         />
    //       </a>
    //       <a
    //         href=" https://apps.apple.com/us/app/radio-mirchi/id1449505212?fbclid=IwAR2lsvz9AAYesCm9e_8_1gh1QgCMHb272D02X4F49RzyA3A6WkrNJEvA1Q4"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //         className="relative"
    //       >
    //         <img
    //           src={AppleStore}
    //           alt="apple-store"
    //           className="mr-5"
    //         />
    //       </a>
    //     </div>
    //   </div>
    // )
  }

export default PlayStoreIcons;