import React from 'react';
import './eventsCard.scss';
import ImageCard from "../imageCard";
import moment from 'moment';

export default function eventsCard (props) {
  return(
    <div className={`events-holder  inline-block mb-5 cursor-pointer relative ${props.home && 'mr-3'}`}
    style={{width:props.cardWidth}}>
    <ImageCard primary src={props.src} alt="events-card" className="w-full" width={props.width} height={props.height} />
    <div className={`events-details flex items-center mt-3 `}>
      <div className={`date flex items-center flex-col uppercase py-2 px-4  rounded-lg w-12 text-xs`}>
      {moment(props.date.slotFrom).format("MMM")}
      <div className="text-2xl">{moment(props.date.slotFrom).format("DD")}</div>
      </div>
      <div className="name ml-2 leading-tight text-base whitespace-normal">{props.name}</div>
    </div>
  </div>
  );
}