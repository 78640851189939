import { Events } from "../../services/events";
import { mutation } from "./mutations";

const handleError = err => {
  console.log("Error in events action : ", err);
};

// Actions
export const getEventsListRequest = () => async dispatch => {
  try {
    let result = await Events.getEventsList();
    dispatch(mutation.setEventsList(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getEventDetailRequest = (payload) => async dispatch => {
  try {
    let result = await Events.getEventDetailList(payload);
    dispatch(mutation.setEventDetailList(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};
