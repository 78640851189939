import React, { Component } from "react";
import "./advertise.scss";
import { Link } from 'react-router-dom';

export default class advertise extends Component {
  render() {
    return (
      <div className="component-advertise py-10 px-5 bg-cover bg-no-repeat  lg:py-3 lg:px-0 lg:flex lg:items-center lg:justify-center ">
        <div className="text-3xl lg:text-xl text-white font-black w-4/5 mb-4 lg:w-auto lg:mr-4 lg:mb-0 lg:leading-1.14">
          Looking for a unique set of advertising solutions? Then feature your brand with us!
        </div>
        <Link className={"block"} to={`/contact-us`}>
          <div className="btn-text text-black text-sm font-black italic leading-none py-3 px-4 uppercase rounded-20 bg-white width-fit-content ">
            ADVERTISE WITH US
        </div>
        </Link>
      </div>
    );
  }
}
