// const setNewsCatList = data => ({
//     type: "SET_NEWS_CAT_LIST",
//     data
// });
// const setCatNewsList = data => ({
//     type: "SET_CAT_NEWS_LIST",
//     data
// });
// const AddNewsList = data => ({
//     type: "ADD_NEWS_LIST",
//     data
// });
// const UnSetNewsList = data => ({
//     type: "UNSET_NEWS_LIST",
//     data
// });

// const setNewsList = data => ({
//     type: "SET_NEWS_LIST",
//     data
// });

// const setActiveNews = data => ({
//     type: "SET_ACTIVE_NEWS",
//     data
// })

const setBlogList = data => ({
    type: "SET_BLOG_LIST",
    data
})

const setBlogDetials = data => ({
    type: "SET_BLOG_DETAILS",
    data
})

const setBlogMedia = data => ({
    type: "SET_BLOG_MEDIA",
    data
})

const setBlogAuthor = data => ({
    type: "SET_BLOG_LIST",
    data
})

const setBlogCategory = data => ({
    type: "SET_BLOG_CATEGORY",
    data
})

const setRelatedBlog = data => ({
    type: "SET_RELATED_BLOG",
    data
})

export const mutation = {
    // setNewsList,
    // setNewsCatList,
    // AddNewsList,
    // setCatNewsList,
    // UnSetNewsList,
    // setActiveNews,
    setBlogList,
    setBlogMedia,
    setBlogAuthor,
    setBlogCategory,
    setBlogDetials,
    setRelatedBlog
};
  