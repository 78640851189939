import { Api } from "./config/request";

export const getContactUsList = () => {
    return Api.getRequest("contact-us");
};

export const ContactUsSendEmail = (payload) => {
    return Api.postRequest("contact-us/send-mail", payload);
};

export const ContactUs = {
    getContactUsList,
    ContactUsSendEmail
};
