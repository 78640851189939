import { WP_Service } from "../../services/wp_service";
import { mutation } from "./mutations";

const handleError = err => {
  console.log("Error in news action : ", err);
};

// Actions
// export const getNewsCatListRequest = () => async dispatch => {
//   try {
//     let result = await WP_Service.getNewsCatList();
//     dispatch(mutation.setNewsCatList(result));
//     return result;
//   } catch (err) {
//     handleError(err);
//   }
// };

// export const getNewsListRequest = () => async dispatch => {
//   try {
//     let result = await WP_Service.getNewsList();
//     dispatch(mutation.setNewsList(result));
//     return result;
//   } catch (err) {
//     handleError(err);
//   }
// };

// export const getNewsListRequestByParams = (cat, params) => async dispatch => {
//   try {
//     let result = await WP_Service.getNewsList(params);
//     dispatch(mutation.setCatNewsList({name: cat, items: result}));
//     dispatch(mutation.AddNewsList(result));
//     return result;
//   } catch (err) {
//     handleError(err);
//   }
// };



// export const getNewsByIdRequest = (id) => async dispatch => {
//   try{
//     let result = await WP_Service.getNewsById(id);
//     dispatch(mutation.setActiveNews(result));
//     return result;
//   } catch(err){
//     handleError(err);
//   }
// }

export const getMediaByIdRequest = (id) => async dispatch => {
  try {
    let result = await WP_Service.getMediaById(id);
    dispatch(mutation.setBlogMedia(result));
    return result;
  }
  catch (err) {
    handleError(err);
  }
}

export const getBlogListRequest = () => async dispatch => {
  try {
    let result = await WP_Service.getBlogList();
    dispatch(mutation.setBlogList(result));
    console.log(result);
    return result;
  } catch (err) {
    handleError(err);
  }
}
export const getBlogByIdRequest = (id) => async dispatch => {
  try {
    let result = await WP_Service.getBlogById(id);
    dispatch(mutation.setBlogDetials(result));
    console.log(result);
    return result;
  } catch (err) {
    handleError(err);
  }
}

export const getCategoryByIdRequest = (id) => async dispatch => {
  try {
    let result = await WP_Service.getCategoryById(id);
    dispatch(mutation.setBlogCategory(result));
    return result;
  } catch (err) {
    handleError(err);
  }
}

export const getAuthorByIdRequest = (id) => async dispatch => {
  try {
    let result = await WP_Service.getAuthorById(id);
    dispatch(mutation.setBlogAuthor(result));
    return result;
  } catch (err) {
    handleError(err);
  }
}



export const getRelatedBlogByIdRequest = (id) => async dispatch => {
  try {
    let result = await WP_Service.getRelatedBlogById(id);
    dispatch(mutation.setRelatedBlog(result));
    return result;
  } catch (err) {
    handleError(err);
  }
}