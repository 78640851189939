import React, { Component } from "react";
import "./home.scss";
import ReactGA from "react-ga";
import CardsHeader from "components/cardsHeader";
import StationCard from "components/stationCard";
import RjCard from "components/rjCard";
import BlogCard from "components/blogCard";
import EventsCard from "components/eventsCard";
import MikeDesktop from "static/images/mike-desktop.png";
import UAEBanner from "static/images/uae-home-banner.png";
import UAEBannerMobo from "static/images/uae-banner-mobo.png";
import MirchiTop20 from "static/images/top20.png";
import ReviewCard from "components/reviewCard";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as stationsActions from "../../store/cityStations/actions";
import * as rjActions from "../../store/rj/actions";
import * as eventsActions from "../../store/events/actions";
import * as uiActions from "../../store/ui/actions";
import * as homeActions from "../../store/home/actions";
import * as mirchiTop20Actions from "../../store/mirchiTop20/actions";
import * as mirchiMurgaActions from "../../store/mirchiMurga/actions";
import * as movieReviewActions from "../../store/movieReviews/actions";
import * as onlineStationsActions from "../../store/onlineStations/actions";
import * as videoActions from "../../store/videos/actions";
import * as sponsorActions from "../../store/sponsors/actions";
import * as PicturesActions from '../../store/pictures/actions';
import * as NewsActions from '../../store/news/actions';
import * as GalleryActions from '../../store/gallery/actions';
import Advertise from "components/advertise";
import Footer from "components/footer";
import ReviewData from "../../data/reviewData.js";
// import Top20Data from "data/top20Data.js";
import MovieCard from "components/movieCard";
import VideoCard from "../../components/videoCard";
// import VideoData from "../../data/videoData";
import AudioCard from "components/AudioCard";
import MurgaNaved from "static/images/murga-naved.jpg";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import 'intersection-observer'
import moment from "moment";
import objectFitImages from 'object-fit-images';
import Modal from 'react-modal';
import FacebookColored from "static/images/pngFiles/facebook-colored.png";
import TwitterColored from "static/images/pngFiles/twitter-colored.png";
import InstagramColored from "static/images/pngFiles/instagram-colored.png";
import TiktokColored from "static/images/pngFiles/tiktok-colored.png";
import CadillacLogo from "static/images/cadillac-logo.png";
import pictures from "../pictures/pictures";
import * as wpActions from "../../store/wp_store/actions"
import { validS3Url } from "../../services/config/request";
// import * as ContestsState from "../../store/contest/action";


class home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      homeSlider: [],
      blogList: [],
      blogDetial: [],
      pictures: [],
      news: [],
      pageYOffset: "",
      logosData: [require('static/images/logo-section.png'), require('static/images/logo-section.png'), require('static/images/logo-section.png'), require('static/images/logo-section.png'), require('static/images/logo-section.png'), require('static/images/logo-section.png'), require('static/images/logo-section.png'), require('static/images/logo-section.png')],
      showPicturesModal: false,
      currentPictureUrl: "",
      cityStationsList:[]
      // contests:[]
    };
    this.selector = React.createRef();
    this.selector1 = React.createRef();
  }

  handleScroll = () => {
    try {
      let rect = this.selector.current.getBoundingClientRect();
      let rect1 = this.selector1.current.offsetTop;
      if (rect.height + 90 > window.innerHeight) {
        if (rect1 - window.pageYOffset - window.innerHeight < 0) {
          this.selector.current.style.bottom = "700px";
          this.selector.current.style.top = "auto";
          this.selector.current.style.position = "fixed";
          this.selector.current.classList.add('top20-fixed')
        } else if (rect.height - (window.pageYOffset - 90 + window.innerHeight) < -30) {
          this.selector.current.style.bottom = "150px";
          this.selector.current.style.top = "auto";
          this.selector.current.style.position = "fixed";
          this.selector.current.classList.add('top20-fixed')
        }
        else {
          this.selector.current.style.bottom = "auto";
          this.selector.current.style.top = "340px";
          this.selector.current.style.position = "absolute"
        }
      } else {
        if (rect1 - (window.pageYOffset + rect.height) < 50) {
          this.selector.current.style.bottom = "200px";
          this.selector.current.style.top = "auto";
          this.selector.current.style.position = "fixed";
          this.selector.current.classList.add('top20-fixed')

        } else {
          this.selector.current.style.bottom = "auto";
          this.selector.current.style.top = "90px";
          this.selector.current.style.position = "fixed";
          this.selector.current.classList.add('top20-fixed')
        }
      }

      if (window.pageYOffset < 25) {
        this.selector.current.style.top = "340px";
        this.selector.current.style.position = "absolute";
        this.selector.current.classList.remove('top20-fixed')
      }
      this.setState({ pageYOffset: window.pageYOffset });
    } catch (error) {
      console.log("error", error);
    }
  };

  async componentDidMount() {
    console.log(this.props.widthState);

    document.title = 'Mirchi 102.4 FM';
    if (window.innerWidth >= 836) {
      window.addEventListener("scroll", this.handleScroll);
    }
    this.props.showLoader(true);
    // this.props.getMirchiTop20ListRequest();
    // this.props.getMirchiMurgaListRequest();
    this.props.getMovieReviewsListRequest();
    this.props.getVideosListRequest();
    this.props.getSponsorsListRequest();
    this.props.getOnlineStationsListRequest();
    this.props.getEventsListRequest();
    this.props.getPicturesList();
    this.props.getNewsListRequest().then((res) =>
      this.setState({
        news: res
      })
    );
    // this.props.getContestListRequest().then((res) => {
    //   this.setState({ contests: res });
    // });

    // this.props.getBlogListRequest().then((res) => {
    //   // console.log(res.data);
    //   this.setState({
    //     blogList: res
    //   })
    // }

    // );
    this.props.getCityStationsListRequest().then((res) => {
      // console.log(res.data);
      this.setState({
        cityStationsList: res
      })
    }

    );


    this.props.showCountDownModalAction(false)
    this.props.getHomeSlider().then(res => {
      console.log("home slider: ", res);
      this.setState({
        homeSlider: res
      })
    })
    if (!this.props.homeList) {
      await this.props.getHomeData();
    }
    window.scrollTo(0, 0);
    this.props.showLoader(false);
    ReactGA.pageview(this.props.location.pathname);
    objectFitImages('ie-img');
    this.props.getGalleryListRequest()
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }


  mapMitchiTop20Date = (fromDate, toDate) => {
    return `${moment(fromDate).format('MMM DD')} - ${moment(toDate).format('MMM DD')} `;
  };
 
  render() {
    const sponserList = this.porps
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    };
    const settings1 = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1
    };
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");


    const adSlider = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      easing: "linear"
    }

    // const liveSponsor = this.props.sponsorsList.filter(x => x.isLive)
    // const sponserList = this.props.sponsorsList.filter(x => !x.isLive);

     // Contest shedule checks whethere the contest in ongoing 
     var today = new Date();
     var dd = String(today.getDate()).padStart(2, "0");
     var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
     var yyyy = today.getFullYear();
     today = dd + "/" + mm + "/" + yyyy;
     function contestvalidation(Date1, Date2) {
       let dateConv1 = new Date(Date1);
       let dateConv2 = new Date(Date2);
 
       let sDate = `${dateConv1.getDate()}/${
         dateConv1.getMonth() + 1
       }/${dateConv1.getFullYear()}`;
 
       let eDate = `${dateConv2.getDate()}/${
         dateConv2.getMonth() + 1
       }/${dateConv2.getFullYear()}`;
 
   
       var from = sDate.split("/");
       var to = eDate.split("/");
       var check = today.split("/");
 
       var fromDate = new Date(from[2], parseInt(from[1])-1, from[0]);  // -1 because months are from 0 to 11
       var toDate   = new Date(to[2], parseInt(to[1])-1, to[0]);
       var checkDate = new Date(check[2], parseInt(check[1])-1, check[0]);
 
       return (checkDate <= toDate && checkDate >= fromDate) ? true : false;
     }
    return (
      <div className="component-home overflow-x-hidden">
        <Helmet>
          <title>Mirchi Radio FM | FM Mirchi Dubai -  Mirchi 102.4 FM Dubai</title>
          <meta name="title" content="Radio Mirchi No. 1 Hindi Radio Station now online in the Qatar" />
          <meta name="description" content="Mirchi, South Asia's no. 1 FM radio station, back in UAE. Its Hot! Stay tuned to Mirchi 102.4 FM station and listen to music, and fun shows. We keep you entertained! Now listen to dubai hindi fm radio mirchi online." />
          <meta name="keywords" content="Radio Mirchi, Radio Mirchi Qatar, Hit Music, Hindi Music,
             Free Hindi Music Online, Bollywood Music, Bollywood Radio Station, Bollywood Songs,
              Radio Station India, Hottest Radio Station, Hindi Radio Station, Radio Mirchi RJs, Latest Hindi
              Music, Latest Songs, Event, Comedy, Prank Call, RJ Naved,  Teekhi Mirchi, Purani Jeans,
               Insta Pyaar,  Mirchi Mornings, Sunset Samosa, 1600 AM, 101.9 FM, 1490 AM, 99.9 FM-HD4,
                92.3 FM-HD2, 103.9 FM HD2, 93.1 FM HD2, 107.5 FM HD2, "/>
          <meta name="og:title" content="Radio Mirchi No. 1 Hindi Radio Station now online in the Qatar" />
          <meta name="og:description" content="Tune into South Asia’s Hottest Music Radio Station Online,
            Radio Mirchi.  Listen to Hit Music, Funny Prank Calls, Celeb Interviews, RJ Shows & More!"/>
          <meta name="og:keywords" content="Radio Mirchi, Radio Mirchi Qatar, Hit Music, Hindi Music,
             Free Hindi Music Online, Bollywood Music, Bollywood Radio Station, Bollywood Songs,
              Radio Station India, Hottest Radio Station, Hindi Radio Station, Radio Mirchi RJs, Latest Hindi
              Music, Latest Songs, Event, Comedy, Prank Call, RJ Naved,  Teekhi Mirchi, Purani Jeans,
               Insta Pyaar,  Mirchi Mornings, Sunset Samosa, 1600 AM, 101.9 FM, 1490 AM, 99.9 FM-HD4,
                92.3 FM-HD2, 103.9 FM HD2, 93.1 FM HD2, 107.5 FM HD2, "/>
        </Helmet>

        <div className="home-section relative min-h-screen">
          <div className={`${this.props.widthState >= 836 ? "image-section relative h-full mb-8" : "img-fluid qatar-banner hidden-desktop"}`}>
            {/* <div className="max-width relative h-full w-full mx-auto pl-5">
              <img
                className="mike-desktop img-fluid hidden-tablet"
                src={MikeDesktop}
                alt="mike-desktop"
              />
              <div className="text-div text-center w-full absolute">
                <div className="heading-text lg:text-xl leading-none text-lg text-white">
                  SOUTH ASIA’S NO.1 RADIO STATION,
                </div>
                <div className="heading-subtext hidden-desktop leading-none italic font-black text-white">
                  NOW IN AMERICA!
                </div>
                <div className="heading-subtext hidden-tablet leading-none italic font-black text-white">
                  <div className="ml-8">NOW IN</div>
                  <div className="ml-20">AMERICA!</div>
                </div>
              </div>
            </div> */}

            {/* Added slider */}

            <div className={`home-slider`}>
              {this.state.homeSlider ?
                <Slider {...adSlider}>
                  {this.state.homeSlider.map((x, index) => {
                    return ( //single-slider inline-block align-top text-base tracking-normal mr-3
                      <a href={x.link}>
                        <img className={`img-fluid ${this.props.widthState >= 836 ? "hidden-tablet uae-banner-desktop" : "qatar-banner hidden-desktop"}`} alt="Mirchi 102.4 FM-Mirchi is back in UAE" src={this.props.widthState >= 836 ? validS3Url(x.image) : validS3Url(x.mob_image)} />
                      </a>
                    );
                  })}
                </Slider>
                : ""
              }
            </div>

            {/* default banner */}
            {/* <img
              className="img-fluid qatar-banner hidden-desktop"
              src={UAEBannerMobo}
              alt="Mirchi 102.4 FM-Mirchi is back in UAE"
            />
            <img
              className="img-fluid hidden-tablet uae-banner-desktop"
              src={UAEBanner} 
              alt="Mirchi 102.4 FM-Mirchi is back in UAE"
            /> */}
          </div>
          <div className="home-outer-container ">
            <div className="home-container2 lg:flex">
              <div className={`home-slider ${this.props.widthState >= 836 ? "w-120" : "w-100"}`}>
                {/* {this.props.cityStationsList &&
                  this.props.cityStationsList
                    .slice(0, 1)
                    .map((item, index) => {
                      return (
                        item.status === "active" && <Link
                          to={`/station/${item.slug}`}
                          className=" mr-3 lg:mr-0 component-card inline-block relative "
                          key={index}
                        >
                          <CardsHeader
                            live
                            showArrows={!!(this.props.cityStationsList && this.props.cityStationsList.length > 4)}
                            viewAll="station"
                            heading="Tune In"
                            headtype="h1"
                            subheading="Click to play the live radio feed from Dubai station"
                          />
                        </Link>
                      );
                    })} */}

                {/* Live Tune In */}
                {this.state.cityStationsList &&
                  this.state.cityStationsList
                    .slice(0, 1)
                    .map((item, index) => {

                      return (item.status === "active" &&
                        <div>
                          <div className="tuneInContainer" >
                            <div className="tuneInContainerText" >
                              {/* {console.log(this.state.contests)} */}
                              <CardsHeader
                                heading={item.SsponsorImage?"Tune in brought to you by":"Tune in"}
                                headtype="h1"
                                size={`${!item.SsponsorImage && this.props.widthState<411?"4":this.props.widthState > 836 ? "4" : this.props.widthState > 411 ? "2" : "1"}`}
                              />
                            </div>
                            {/* <h1>Tune in</h1> */}
                            <div  >
                              <div className="sponsorLogo">
                                {/* {liveSponsor.length ? */}
                              
                                {/* <img src={item.SsponsorImage} style={{ border: "5px solid #ed1c23" }} /> */}
                              
                                {/*{liveSponsor[0].image} */}
                                {/* :''} */}
                                {/* <img src={"https://mmo.aiircdn.com/265/6028bece40736.png"} /> */}
                              </div>
                            </div>
                          </div>
                          {/* )})} */}
                          <div className="stations-section px-5 lg:pt-8">
                            <div
                              className={`stations-slider ${this.props.widthState >= 836 ? "" : "mobile-tray"}
                   block lg:flex lg:items-center overflow-x-scroll lg:px-0  px-5`}
                            >
                              {/* {this.props.cityStationsList &&
                      this.props.cityStationsList
                        .slice(0, 1)
                        .map((item, index) => {
                          return ( */}
                              <Link
                                to={`/station/${item.slug}`}
                                className=" mr-3 lg:mr-0 component-card inline-block relative "
                                key={index}
                              >
                                {/* <CardsHeader
                                  // live
                                  showArrows={(this.props.cityStationsList && this.props.cityStationsList.length > 1)}
                                  viewAll="station"
                                  // heading="Tune In"
                                  // headtype="h1"
                                  subheading="Click to play the live radio feed from Dubai station"
                                /> */}
                                <StationCard 
                                  home
                                  primary
                                  showPlayIcon={true}
                                  stationCard
                                  src={item.image}
                                  name={item.name}
                                  shortFor={item.shortFor}
                                  frequency={item.frequency}
                                />
                              </Link>
                              {/* );
                        })} */}
                            </div>
                          </div></div>
                      )
                    })}
              </div>
              <div className={`home-slider ${this.props.widthState >= 836 ? "w-50" : "w-100"}`} >
                <div className={`flex ${this.props.widthState >= 836 ? "" : "px-5"}`}>
                  <div>
                    <CardsHeader
                      heading="Follow us"
                    />
                  </div>
                  <div className="flex" style={{ paddingTop: "10px" }}>
                    <a
                      href="https://www.facebook.com/mirchiuae"
                      target="_blank"  
                      rel="noopener noreferrer"
                      className="relative"
                    >
                      <img
                        src={FacebookColored}
                        alt="facebook"
                        className="h-8 w-8 mr-5"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/mirchi_uae/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="relative"
                    >
                      <img
                        src={InstagramColored}
                        alt="instagram"
                        className="h-8 w-8 mr-5"
                      />
                    </a>
                    <a
                      href="https://www.twitter.com/mirchiuae"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="relative"
                    >
                      <img
                        src={TwitterColored}
                        alt="instagram"
                        className="h-8 w-8 mr-5"
                      />
                    </a>
                    <a
                      href="https://vm.tiktok.com/ZSeJGqFSQ/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="relative"
                    >
                      <img
                        src={TiktokColored}
                        alt="tiktok"
                        className="h-8 w-8 mr-5"
                      />
                    </a>
                  </div>
                </div>

                {/* Facebook section */}
                <div className={`fb ${this.props.widthState >= 830 ? "" : "px-5"}`}>
                  <iframe
                    src={"https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmirchiuae&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=990509118399576"}
                    // width={340} 
                    height={500}
                    style={{ border: "none", overflow: "hidden", width: "100%" }}
                    scrolling={"no"}
                    frameborder={"0"}
                    allowfullscreen={"true"}
                    allow={"autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"}></iframe>
                </div>

              </div>
            </div>
          </div>
          {!this.props.isLoading && (
            <div className="home-outer-container ">
              <div className="home-container">


                {/* Blogs */}
                <div className="rjs-section px-5 mb-2 lg:pt-8 lg:mb-0">
                  {/* <CardsHeader
                    showArrows={!!(this.state.blogList && this.state.blogList.length > 4)}
                    viewAll="new-on-mirchi"
                    heading="New on Mirchi"
                    headtype="h2"
                    subheading="Updated trends every day"
                  /> */}
                  <div className={`rjs-carousel relative hidden-tablet ${(msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) && 'ie-carousel'}`}>
                    <Slider {...settings}>
                      {this.state.blogList &&
                        this.state.blogList.map((item, index) => {
                          return (
                            <Link
                              className="rj-card relative inline-block component-card "
                              to={`/new-on-mirchi/${item.slug}`}
                              key={index}
                            >
                              <BlogCard
                                home
                                rjShadow
                                rjHomeCard
                                name={item.title.rendered}
                                height="355px"
                                width={(msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) ? "190px" : "200px"}
                                key={index}
                                data={item}
                              />
                            </Link>
                          );
                        })}
                    </Slider>
                  </div>
                  <div className="rjs-slider mobile-tray hidden-desktop block items-center overflow-x-scroll pl-5 pr-0 w-full">
                    {this.state.blogList &&
                      this.state.blogList.map((item, index) => {
                        return (
                          <Link
                            className="rj-card component-card relative inline-block"
                            to={`/new-on-mirchi/${item.slug}`}
                            key={index}
                          >
                            <BlogCard
                              home
                              rjShadow
                              cardWidth={"55%"}
                              src={"item.image"}
                              name={item.title.rendered}
                              height="275px"
                              data={item}
                            />
                          </Link>
                        );
                      })}
                  </div>
                </div>




                {/*meet the  Rj's */}
                <div className="rjs-section px-5 mb-2 lg:pt-8 lg:mb-0">
                  <CardsHeader
                    showArrows={!!(this.props.rjList && this.props.rjList.length > 4)}
                    viewAll="rj"
                    heading="Meet The RJs"
                    headtype="h2"
                    subheading="Your favorite show hosts"
                  />
                  <div className={`rjs-carousel relative hidden-tablet ${(msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) && 'ie-carousel'}`}>
                    <Slider {...settings}>
                      {this.props.rjList &&
                        this.props.rjList.map((item, index) => {
                          return (
                            <Link
                              className="rj-card relative inline-block component-card"
                              to={`/rj/${item.slug}`}
                              key={index}
                            >
                              <RjCard
                                home
                                rjShadow
                                rjHomeCard
                                src={item.image}
                                name={item.name}
                                height="355px"
                                width={(msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) ? "190px" : "200px"}
                              />
                            </Link>
                          );
                        })}
                    </Slider>
                  </div>
                  <div className="rjs-slider mobile-tray hidden-desktop block items-center overflow-x-scroll pl-5 pr-0">
                    {this.props.rjList &&
                      this.props.rjList.map((item, index) => {
                        return (
                          <Link
                            className="rj-card component-card relative inline-block"
                            to={`/rj/${item.slug}`}
                            key={index}
                          >
                            <RjCard
                              home
                              rjShadow
                              cardWidth={"55%"}
                              src={item.image}
                              name={item.name}
                              // cardWidth="195px"
                              height="275px"
                            />
                          </Link>
                        );
                      })}
                  </div>
                </div>

                {/* top 20 section */}
                {/* {this.props.mirchiTop20List && window.innerWidth <= 1024 ?
                  <div className="top20-section hidden-desktop py-8 px-0 mb-6">
                    <div className="text-white text-center font-black text-3xl px-6 tracking-tighter mb-2">
                      Mirchi Top 20
                    </div>
                    <div className="text-sm text-center text-white mb-5 px-6 tracking-tight ">
                      India's most authentic weekly music countdown!
                    </div>
                    <div
                      className="date-pill mb-8 text-center py-2 px-3 tracking-tight text-sm font-black text-white">
                      {this.mapMitchiTop20Date(this.props.mirchiTop20List && this.props.mirchiTop20List.start_date,
                        this.props.mirchiTop20List && this.props.mirchiTop20List.end_date)}
                    </div>
                    //  Carousel 
                    <div className="top20-carousel">
                      <div className="top20-slider mobile-tray flex block items-center overflow-x-scroll">
                        {this.props.mirchiTop20List && this.props.mirchiTop20List.countdown.slice(0, 5).map((item, index) => {
                          return (
                            <div
                              className="top20-card inline-block  pr-5"
                              key={index}
                            >
                              <MovieCard
                                homeCard
                                currentRank={item.position}
                                changingRank={item.movement}
                                lastWeekPosition={item.last_week_position}
                                src={item.bigimage || item.thumbnail}
                                movieName={item.album}
                                songName={item.title}
                                height="265px"
                                top20Listing
                                video={item.video}
                                embedVideo={item.embedVideo}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <Link to={`/mirchi-top20`}>
                      <div
                        className="countdown-div rounded-20 text-center italic tracking-tight text-white font-black text-sm py-3">
                        VIEW FULL COUNTDOWN
                      </div>
                    </Link>
                  </div> : ""}
                {this.props.mirchiTop20List && window.innerWidth >= 1024 ?
                  <div
                    className={`top20-section rounded-lg absolute hidden-tablet`}
                    ref={this.selector}>
                    <div className="top-section w-full relative">
                      <img
                        className="top-20 absolute"
                        src={MirchiTop20}
                        alt="mirchi-top20-img"
                      />
                      <div
                        className="mirchi-text authentic-text absolute left-0 right-0 w-3/4 mx-auto  hidden-tablet  text-center text-white text-sm">
                        India's most authentic weekly music countdown!
                      </div>
                      <div className="date-pill text-center py-2 px-3 tracking-tight text-sm font-black text-white">
                        {this.mapMitchiTop20Date(this.props.mirchiTop20List && this.props.mirchiTop20List.start_date,
                          this.props.mirchiTop20List && this.props.mirchiTop20List.end_date)}
                      </div>
                    </div>

                    <div className="top-20-container  ">
                      {this.props.mirchiTop20List && this.props.mirchiTop20List.countdown.slice(0, 5).map((item, index) => {
                        return <div className="active-songs p-4" key={index}>
                          <MovieCard
                            active
                            top20
                            sideBar
                            currentRank={item.position}
                            changingRank={item.movement}
                            lastWeekPosition={item.last_week_position}
                            src={item.bigimage || item.thumbnail}
                            movieName={item.album}
                            songName={item.title}
                            height="60px"
                            width="60px"
                            video={item.video}
                            embedVideo={item.embedVideo}
                          />
                        </div>
                      })}
                    </div>
                    <Link to={'/mirchi-top20'}
                      className="full-list w-full py-4 px-12 italic font-black text-sm
                   tracking-tight block text-center text-white text-center">
                      VIEW FULL COUNTDOWN
                    </Link>
                  </div>
                  : ""} */}

                {this.props.eventsList && this.props.eventsList.length ? (
                  <div className="events-section px-5 mt-10">
                    <CardsHeader
                      showArrows={!!(this.props.eventsList && this.props.eventsList.length > 4)}
                      viewAll="event"
                      heading="Events"
                      subheading="Events near you"
                    />
                    <div className="events-carousel  relative hidden-tablet">
                      <Slider {...settings}>
                        {this.props.eventsList &&
                          this.props.eventsList.map((item, index) => {
                            return (
                              <Link
                                key={index}
                                className="events-card "
                                to={`/event/${item.id}`}
                              >
                                <EventsCard
                                  home
                                  height="145px"
                                  src={item.image[0]}
                                  name={item.name}
                                  date={item.date}
                                />
                              </Link>
                            );
                          })}
                      </Slider>
                    </div>
                    <div className="events-slider mobile-tray hidden-desktop flex overflow-x-scroll">
                      {this.props.eventsList &&
                        this.props.eventsList.map((item, index) => {
                          return (
                            <Link
                              className="events-card  component-card"
                              to={`/event/${item.id}`}
                              key={index}
                            >
                              <EventsCard
                                home
                                height="145px"
                                cardWidth={"80%"}
                                src={item.image[0]}
                                name={item.name}
                                date={item.date}
                              />
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="video-section px-5 lg:pt-8">
                  <div>
                    <CardsHeader
                      showArrows={!!(this.props.videoList && this.props.videoList.length > 4)}
                      viewAll="videos"
                      heading="Videos"
                      subheading="From our studios"
                    />
                  </div>
                  {window.innerWidth >= 836 && this.props.videoList ? (
                    <div className=" videos-slider   relative hidden-tablet">
                      <Slider {...settings}>
                        {this.props.videoList.map((video, index) => {
                          return (
                            <VideoCard
                              width="100%"
                              // height="auto"
                              homeListing
                              key={index}
                              index={index}
                              link={video.link}
                              thumbail={video.image}
                              title={video.title}
                              tag={video.tag}
                            />
                          );
                        })}
                      </Slider>
                    </div>
                  ) : (<div className="videos-slider flex mobile-tray hidden-desktop overflow-x-scroll">
                    {this.props.videoList && this.props.videoList.map((video, index) => {
                      return (
                        <VideoCard
                          width="250px"
                          // height="145px"
                          homeListing
                          key={index}
                          index={index}
                          link={video.link}
                          thumbail={video.thumbail}
                          title={video.title}
                          tag={video.tag}
                        />
                      );
                    })}
                  </div>
                  )}
                </div>

                {/* //Pictures Section */}
                <div className="station-section px-5 lg:pt-8">
                  <Modal
                    isOpen={this.state.showPicturesModal}
                    style={{
                      top: '50%',
                      left: '50%',
                      right: 'auto',
                      bottom: 'auto',
                      marginRight: '-50%',
                      transform: 'translate(-50%, -50%)',
                      content: {
                        width: "500px",
                        height: "530px",
                        marginTop: "50px",
                        marginLeft: "50%",
                        transform: "translateX(-80%)",
                        paddingBottom: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                      }
                    }}
                    onRequestClose={() => {
                      this.setState({
                        showPicturesModal: false
                      })
                    }}
                  >
                    <button style={{
                      position: "absolute",
                      top: "21px",
                      left: "20px",
                    }} onClick={() => (this.setState({ showPicturesModal: false }))}>x</button>
                    <img src={validS3Url(this.state.currentPictureUrl)} style={{ transform: "", width: "400", objectFit: "contain", height: "256px", borderRadius: "15px", marginTop: "15px" }} />
                  </Modal>
                  <div>
                    <CardsHeader
                      showArrows={!!(this.props.gallery && this.props.pictures.pictures.length > 4)}
                      // noViewAll
                      viewAll="/pictures"
                      heading="Pictures"
                    />
                  </div>
                  {this.props.pictures.pictures ? (
                    <div className=" videos-slider online-stations-slider  relative">
                      <Slider {...settings}>
                        {this.props.pictures.pictures.map((gallery, index) => {
                          return (
                            <div>
                              <Link
                                // to={`/gallery/${gallery.slug}`}
                                to={"/pictures"}
                              >
                                <img
                                  className="pic-img"
                                  src={validS3Url(gallery.image)}
                                  onClick={() => (
                                    this.setState({
                                      showPicturesModal: true,
                                      currentPictureUrl: gallery.image
                                    })
                                  )}
                                  style={{
                                    width: "188px",
                                    height: "150px",
                                    objectFit: "cover",
                                    //transform: "rotate(-90deg)"
                                  }}
                                />
                              </Link>

                              <div style={{ paddingLeft: "5px", paddingRight: "10px", marginTop: "10px" }}>
                                <h3>{gallery.name}</h3>
                                {/* <p style={{ fontWeight: "100", fontSize: "13px", marginTop: "10px", color: "#888787", textAlign: "justify" }}>{station.description}</p> */}
                              </div>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  ) : ""}
                  {/* : (
                      <div className="videos-slider online-stations-slider flex mobile-tray hidden-desktop overflow-x-scroll">
                        {this.props.onlineStationsList && this.props.onlineStationsList.map((station, index) => {
                          return (
                            <a href={station.link} target="_blank">
                              <img className="online-station-image inline-block align-top text-base tracking-normal mr-3" style={{padding:"5px",borderRadius:"50%"}} src={station.image} />
                            </a>
                          );
                        })}
                      </div>
                    ) */}

                </div>

                {/* news section */}
                {/* <div className="station-section px-5 lg:pt-8">
                   <Modal
                    isOpen={this.state.showPicturesModal}
                    style={{
                      top: '50%',
                      left: '50%',
                      right: 'auto',
                      bottom: 'auto',
                      marginRight: '-50%',
                      transform: 'translate(-50%, -50%)',
                      content: {
                        width: "500px",
                        height: "530px",
                        marginTop: "50px",
                        marginLeft: "50%",
                        transform: "translateX(-80%)",
                        paddingBottom: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                      }
                    }}
                    onRequestClose={() => {
                      this.setState({
                        showPicturesModal: false
                      })
                    }}
                  >
                    <button style={{
                      position: "absolute",
                      top: "21px",
                      left: "20px",
                    }} onClick={() => (this.setState({ showPicturesModal: false }))}>x</button>
                    <img src={this.state.currentPictureUrl.image} style={{ transform: "", width: "400", objectFit: "contain", height: "256px", borderRadius: "15px", marginTop: "15px" }} />
                  </Modal> 
                  <div>
                    <CardsHeader
                      showArrows={!!(this.state.news && this.state.news.length > 4)}
                      // noViewAll
                      viewAll="/news"
                      heading="News"
                    />
                  </div>
                  {this.state.news ? (
                    <div className=" videos-slider online-stations-slider  relative">
                      <Slider {...settings}>
                        {this.state.news.map((news, index) => {
                          return (
                            <div>
                              <Link
                                to={`/news`}
                              >
                                <img
                                  className="pic-img"
                                  src={news.image}
                                  // onClick={() => (this.setState({
                                  //   showPicturesModal: true,
                                  //   currentPictureUrl: news
                                  // }))}
                                  style={{
                                    width: "188px",
                                    height: "150px",
                                    objectFit: "cover",
                                    //transform: "rotate(-90deg)"
                                  }}
                                />
                                <div style={{ paddingLeft: "5px", paddingRight: "10px", marginTop: "10px" }}>
                                  <h3 style={{ lineHeight: "20px" }}>{news.title}</h3>
                                  {/* <div className="newsDes" 
                                    dangerouslySetInnerHTML={{
                                      __html: news.description, 
                                    }}>
                                  </div> 
                                </div>
                                 <div style={{ paddingLeft: "0px", marginTop: "10px" }}>
                                  <h3 style={{ paddingLeft: "5px", fontWeight: "bold" }}>{"station.title"}</h3>
                                  <p className="fixedContainer">{"asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf  "}</p>
                                </div>  
                              </Link>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  ) : ""}
                   <div className="videos-slider online-stations-slider flex mobile-tray hidden-desktop overflow-x-scroll">
                    {this.props.onlineStationsList && this.props.onlineStationsList.map((station, index) => {
                      return (
                        <a href={station.link} target="_blank">
                          <img className="online-station-image inline-block align-top text-base tracking-normal mr-3" style={{ padding: "5px", borderRadius: "50%" }} src={station.image} />
                        </a>
                      );
                    })}
                  </div> 
                  

                </div> */}

                {/* //online stations section */}
                < div className="station-section px-5 lg:pt-8">
                  <div>
                    <CardsHeader
                      showArrows={!!(this.props.onlineStationsList && this.props.onlineStationsList.length > 4)}
                      noViewAll
                      heading="Mirchi Web Radios, on Gaana"
                    />
                  </div>
                  {window.innerWidth >= 836 && this.props.onlineStationsList ? (
                    <div className=" videos-slider online-stations-slider  relative hidden-tablet">
                      <Slider {...settings}>
                        {this.props.onlineStationsList.map((station, index) => {
                          return (
                            <a href={station.link} target="_blank" style={{ padding: "5px" }} >
                              <img className="online-station-image" style={{ marginRight: "10px" }} src={validS3Url(station.image)} />
                            </a>
                          );
                        })}
                      </Slider>
                    </div>

                  ) : (
                    <div className="videos-slider online-stations-slider flex mobile-tray hidden-desktop overflow-x-scroll">
                      {this.props.onlineStationsList && this.props.onlineStationsList.map((station, index) => {
                        return (
                          <a href={station.link} target="_blank">
                            <img className="online-station-image inline-block align-top text-base tracking-normal mr-3" src={validS3Url(station.image)} />
                          </a>
                        );
                      })}
                    </div>
                  )}
                </div>
                {/* <div className="station-section px-5 lg:pt-8" style={{ marginBottom: "-60px" }}>
                  <CardsHeader
                    showArrows={!!(this.props.onlineStationsList && this.props.onlineStationsList.length > 4)}
                    noViewAll
                    heading="Mirchi Murga"
                  />
                </div> */}
                {/* <div className="mirchi-murga-section relative py-5 lg:ml-5 lg:mx-8 mt-16 mb-6 bg-no-repeat">

                  <div className="absolute rj-picture">
                    <img
                      src={require("../../static/images/naved-murga.png")}
                      alt="rj naved"
                    />
                  </div>
                  <div className="pt-40 px-5 lg:px-5 flex flex-wrap">
                    <div className="flex justify-end w-full pb-4 pr-2">
                      <Link to="/mirchi-murga">
                        <div className="lg:flex hidden">
                          <div className="hidden-tablet font-black italic tracking-tight leading-none text-lg mr-3">
                            View All
                          </div>
                          <img src={require('static/images/view-all-arrow.svg')} alt="arrow-img" />
                        </div>
                      </Link>
                    </div>
                    <div className="flex flex-wrap flex-col lg:flex-row w-full justify-center">
                      {this.props.mirchiMurgaList && this.props.mirchiMurgaList.slice(0, 4).map((data, index) => {
                        return (
                          <div className="lg:w-1/4 w-full" key={index}>
                            <AudioCard
                              homeMurga={true}
                              key={index}
                              title={data.title}
                              link={data.link}
                              type={"murga"}
                              date={data.date}
                              image={MurgaNaved}
                              description={"By RJ Naved"}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <Link to="/mirchi-murga">
                    <div
                      className="lg:hidden block font-black bg-white rounded-full uppercase tracking-tight py-3 mx-10 lg:mx-20 text-center text-sm italic">
                      VIEW ALL EPISODES
                    </div>
                  </Link>
                </div> */}

              </div>

              {/* <div className="logos-section px-5 mb-2 lg:pt-8">
                <CardsHeader
                  showArrows={this.state.logoData && this.state.logoData.length > 6}
                  heading="Sponsors"
                  noViewAll
                />
                <div className="logos-carousel logos-slider mb-8 relative hidden-tablet">
                  <Slider {...settings1} className={"logo-slider"}>
                    {sponserList &&
                      sponserList.map((item, index) => {
                        return (
                          <a target="_blank" rel="noopener noreferrer" href={item.link} className="logo-container" key={index}>
                            <div alt="logo-section" style={{ backgroundImage: `url(${item.image})` }} className="sponsors-logo" />
                          </a>
                        );
                      })}
                  </Slider>
                </div>
                <div className={`logo-slider ${this.props.widthState >= 1024 ? "" : "mobile-tray"}
                    block hidden-desktop lg:flex lg:items-center overflow-x-scroll lg:px-0  px-5`}>
                  {this.props.sponsorsList &&
                    this.props.sponsorsList.map((item, index) => {
                      return (
                        <a target="_blank" rel="noopener noreferrer" href={item.link} className="logo-container" key={index}>
                          <div alt="logo-section" style={{ backgroundImage: `url(${item.image})` }} className="sponsors-logo" />
                        </a>
                      );
                    })}
                </div>
              </div> */}
            </div>
          )}
        </div>
        <div ref={this.selector1}>
          <Advertise />
        </div>
        <Footer />
      </div>
    );
  }
}

export default connect(
  ({ cityStations, rj, events, ui, home, mirchiTop20, mirchiMurga, movieReviews, videos, sponsors, onlineStations, pictures, news, gallery }) => ({
    ...cityStations,
    ...rj,
    ...events,
    ...ui,
    ...home,
    ...mirchiTop20,
    ...mirchiMurga,
    ...movieReviews,
    ...videos,
    ...sponsors,
    ...onlineStations,
    ...news,
    pictures: pictures,
    ...gallery,
  }),
  dispatch =>
    bindActionCreators(
      {
        ...stationsActions, ...rjActions, ...eventsActions, ...uiActions, ...sponsorActions,
        ...homeActions, ...mirchiTop20Actions, ...mirchiMurgaActions, ...movieReviewActions, ...videoActions, ...onlineStationsActions, ...wpActions, ...PicturesActions, ...NewsActions, ...GalleryActions
      },
      dispatch
    )
)(home);
