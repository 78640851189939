import { Api } from "./config/request";

export const getContestList = () => {
  return Api.getRequest("contest/");
};


export const getContestDetail = (id) => {
  return Api.getRequest(`contest/${id}`);
};


export const Contest = { 
    getContestList ,
    getContestDetail,
};
