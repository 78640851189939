export const initialState = {
    // newsCategories: [],
    // newsList: [],
    // catNewsList: [],
    // activePost: {},
    blogs: [],
    blogDetails:null,
    media:[],
    author:[],
    category:[],
    relatedBlog:[]
};