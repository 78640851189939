import { initialState } from "./state";

export const cityStations = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CITYSTATIONS_LIST": {
      return Object.assign({}, state, {
        cityStationsList: action.data
      });
    }
    case "SET_RJDATA": {
      return Object.assign({}, state, {
        rjDataList: action.data
      });
    }
    case "SET_ACTIVE_CITYSTATIONS": {
      return Object.assign({}, state, {
        activeCityStations: action.data
      });
    }
    default:
      return state;
  }
};
