import React from "react";
import "./stationCard.scss";
import ImageCard from "../imageCard";
import PlayButton from "static/images/play-button.svg";
export default function stationCard(props) {
  return (
    <div
      className={`stations-holder w-full  inline-block  lg:mb-4 cursor-pointer relative ${props.home &&
        "mr-3 lg:mr-0"} ${props.stations && "home-stations mb-5"} ${ props.stationWidth || props.stationDetails ? " w-full mb-0" : "mb-5"
      } ${props.stationDesktopCard && "station-desktop-card"}`}
    >
     
      <ImageCard
        stations={props.stations}
        home={props.home}
        src={props.src}
        alt="rj-card"
        stationCard={!!props.stationCard}
        primary={!!props.primary}
        className="w-full"
        width={props.width}
        height={props.height}
        type={'stationCard'}
        stationDetailBanner={props.stationDetailBanner}
      />
      <div
        className={`station-details absolute ${props.stations && 'lg:pr-3'} ${props.stationWidth &&
          "station-position"} ${props.stationDetailBanner && "station-details-banner"} `}
      >
       {props.showPlayIcon ? (
        <img
          src={PlayButton}
          className="relative z-10 left-0 mb-1 play-icon h-12 w-12"
          alt="play"
        />
      ) : (
        ""
      )}
        <div
          className={`station-name ${props.stations &&
            "home-station-name"} lg:leading-none uppercase text-white font-black
           text-2xl lg:text-base  mb-1 ${props.shortFor ? 'lg-mb:1': 'lg:mb-2'} ${props.stationWidth &&
             "lg:text-5xl text-3xl leading-tight"}`}
        >
          {props.name}
        </div>
        
        {props.shortFor ?  <div
          className={`station-short-name lg:leading-none text-white font-medium text-sm mb-2
          ${props.stationWidth && "leading-tight"} ${props.stationDetailBanner && "text-base"}`}>
          {props.shortFor}
        </div>: ''}
  
        <div className={`flex ${props.stationDetailBanner && window.innerWidth >= 1024 ?  'flex-row ': 'flex-col' }`}>
        {props.frequency.map((frequency, index) => {
          return <div
            key={index}
            className={`frequency bg-white text-xs lg:text-sm ${props.home ? 'home': ''} ${props.stationWidth &&
            "text-base"} ${props.frequency.length > 1 ? 'multiple' : ''} ${props.stationDetailBanner && window.innerWidth >= 1024 ?  'stationBanner': '' }`}
          >
              {frequency}
          </div>
        })}
        </div>
       
      </div>
    </div>
  );
}
