import { initialState } from "./state";

export const faq = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FAQ_LIST": {
      return Object.assign({}, state, {
        faqsList: action.data
      });
    }
    case "SET_ACTIVE_FAQS": {
      return Object.assign({}, state, {
        activeFaqs: action.data
      });
    }
    default:
      return state;
  }
};
