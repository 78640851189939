const setEventsList = data => ({
  type: "SET_EVENTS_LIST",
  data
});

const setEventDetailList = data => ({
  type: "SET_EVENT_DETAIL_LIST",
  data
});

const setActiveEvents = data => ({
  type: "SET_ACTIVE_EVENTS",
  data
});


export const mutation = {
  setEventsList,
  setActiveEvents,
  setEventDetailList,
};
