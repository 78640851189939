import React, { Component, Suspense, lazy } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import Home from "views/home";
// import MirchiTop20 from "views/mirchiTop20";
import Header from "components/header";
import GlobalPlayer from "components/globalPlayer";
import videoModal from "components/videoModal";
import { storage as LocalStorage } from "services/config/storage";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as uiActions from "store/ui/actions";
import * as contactUsActions from "store/contactUs/actions";
// import FaqHDRadio from "views/faqHDRadio"
import Loader from "static/images/loader.svg";
import Close from "static/images/cross.svg";
import Logo from "static/images/uae-logo.jpg";
import Share from "static/images/share.png";
import ReactGA from "react-ga";
import CountDownData from "../data/countDownData";
import Contest from "../views/Contest/contest";
import ContestPage from "../views/Contest/reusableContest/contest_page";
import Blogs from "../views/blogs/blogs"
import BlogsPage from "../views/blogs/reusableBlog/blogs_page"
import NewsPage from "../views/news/reusableNews/news_page";
// const MirchiTop20Page = lazy(() => import("views/mirchiTop20/"));
const VideosPage = lazy(() => import("views/videos/"));
const PicturesPage = lazy(() => import("views/pictures/pictures.js"));
const News = lazy(() => import("views/news/"));
const GalleryPage = lazy(() => import("views/gallery/"));
const GalleryImages = lazy(() => import('views/galleryImages/'));
const AboutUsPage = lazy(() => import("views/aboutUs/"));
const ContactUsPage = lazy(() => import("views/contactUs/"));
const ReviewsPage = lazy(() => import("views/reviews/"));
const EventRoutesPage = lazy(() => import("./eventRoutes"));
const RJRoutesPage = lazy(() => import("./rjRoutes"));
const StationRoutesPage = lazy(() => import("./stationRoutes"));
const ShowRoutesPage = lazy(() => import("./showRoutes"));
const MirchiMurgaPage = lazy(() => import("views/mirchiMurgaPlaylist/"));
const FaqHDRadioPage = lazy(() => import("views/faqHDRadio/"));
const MirchiPodcast = lazy(() => import('views/mirchiPodcast/'));
const PodcastDetail = lazy(() => import('views/podcastDetail/'));

const routes = [
  {
    path: "/",
    component: Home,
    exact: true,
  },
  // {
  //   path: "/mirchi-top20",
  //   component: MirchiTop20Page,
  //   exact: true,
  // },
  {
    path: "/videos",
    component: VideosPage,
    exact: true,
  },
  {
    path: "/pictures",
    component: PicturesPage,
  },
  {
    path: "/news/:news_id",
    component: NewsPage,
  },
  {
    path: '/podcast',
    component: MirchiPodcast,
    exact: true
  },
  {
    path: '/podcast/:id',
    component: PodcastDetail,
    exact: true
  },
  {
    path: "/news",
    component: News,
  },
  {
    path: '/gallery/:slug',
    component: GalleryImages,
    exact: true
  },
  {
    path: "/about-us",
    component: AboutUsPage,
  },
  {
    path: "/contact-us",
    component: ContactUsPage,
  },
  {
    path: "/contest/:slug",
    component: ContestPage,
  },
  {
    path: "/contest",
    component: Contest,
  },
  {
    path: "/new-on-mirchi/:slug",
    component: BlogsPage,
  },
  {
    path: "/new-on-mirchi",
    component: Blogs,
  },
  // {
  //   path: "/contests/:contest_id",
  //   component: ContestPage,
  // },

  /*
  TODO: Uncomment to get back mirchi reviews
  {
    path: "/reviews",
    component: ReviewsPage,
    exact: true,
  },*/
  {
    path: "/event",
    component: EventRoutesPage,
    exact: false,
  },
  {
    path: "/rj",
    component: RJRoutesPage,
    exact: false,
  },
  {
    path: "/station",
    component: StationRoutesPage,
    exact: false,
  },
  {
    path: "/mirchi-murga",
    component: MirchiMurgaPage,
    exact: true,
  },
  {
    path: "/tune-hd",
    component: FaqHDRadioPage,
    exact: true,
  },
  {
    path: "/show",
    component: ShowRoutesPage,
    exact: false,
  },
];

class RootRouter extends Component {
  constructor() {
    super();
    this.state = {
      showInstallMessage: false,
      showSubItemCountDown: "",
    };
  }

  async componentDidMount() {
    const userAgent = window.navigator.userAgent;
    if (!LocalStorage.fetch.showInstallMessage() && window.innerWidth < 1024) {
      // if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || navigator.userAgent.match('CriOS')) {
      //   this.setState({showInstallMessage: false});
      // } else {
      this.setState({ showInstallMessage: true });
      if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        if (userAgent.indexOf("Safari") !== -1) {
          if (/^((?!chrome|android|crios|fxios).)*safari/i.test(userAgent)) {
            document.body.style.overflow = "hidden";
          }
        }
      } else {
        document.body.style.overflow = "hidden";
      }
      // }
    }

    await this.props.checkForUS();
    if (!this.props.activeContactUs) {
      this.props.getContactUsListRequest();
    }
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, 1000);
    ReactGA.initialize("G-VWXGK210MV");
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.showshowInquiryModalAction(false);
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      if (this.props.audioType !== "radio") {
        await this.props.hideAudioPlayerData();
      }
      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, 50);
    }
  }

  handleInstallMessage = () => {
    this.setState({ showInstallMessage: false });
    LocalStorage.set.showInstallMessage(true);
    document.body.style.removeProperty("overflow");
  };

  handleCountDownClick = (countdown) => {
    if (countdown.subItems) {
      this.setState({
        showSubItemCountDown: countdown.heading,
      });
    }
  };

  render() {
    const userAgent = window.navigator.userAgent;
    const routeComponents = routes.map(({ path, component, exact }, key) => {
      return exact ? (
        <Route exact path={path} component={component} key={key} />
      ) : (
        <Route path={path} component={component} key={key} />
      );
    });
    return (
      <div
        className={`app-router h-auto ${
          this.props.showGlobalPlayer ? "globalPlayer" : ""
        }`}
      >
        {this.props.isLoading && (
          <div className="loader-div">
            <img className="app-loader" src={Loader} alt="loader" />
          </div>
        )}
        {this.props.showInquiryModal ? (
          <div
            className={`inquiry-modal
                   ${this.props.showInquiryModal ? "active" : ""}
                   `}
          >
            <div className="inner-content  py-5 px-5 w-full">
              <div
                className={`relative text-white  w-full for-inquery rounded ${
                  this.props.showInquiryModal && "active"
                }`}
              >
                <img
                  onClick={() => this.props.showshowInquiryModalAction(false)}
                  className=" absolute right-0  w-6 top-0 rounded-full close-icon img-fluid"
                  src={Close}
                  alt=""
                />
                <div className="inq-text">
                  For advertising/ enquires, <span>contact us on:</span>
                </div>
                <div className="flex contact mt-2 opacity-50">
                  <div className="email-block contact-holder mr-3">
                    <span className="key">Mail: </span>
                    {/*this.props.activeContactUs && this.props.activeContactUs[0]
                      ? this.props.activeContactUs[0].email
                      : ""*/}
                    <a href="mailto:sales@mirchi.ae">sales@mirchi.ae</a>
                    <br />
                    <span className="key">Contact Number: </span>
                    <a href="tel:+97144534950">+971-44534950</a>
                  </div>
                  {/* TODO: Remove this for getting phone number back on contact us */}
                  {/* <div className="ph-block contact-holder">
                    <span className="key">ph: </span>
                    {this.props.activeContactUs && this.props.activeContactUs[0]
                      ? this.props.activeContactUs[0].phone
                      : ""}
                  </div> */}
                </div>
                <br />
                <div className="inq-text" style={{ marginBottom: "10px" }}>
                  Office Address :
                </div>
                <div className="flex contact mt-2 opacity-50">
                  Mirchi 1024 <br />
                  Office No: 1602,
                  <br />
                  Al Shafar Tower 1, Barsha Heights,
                  <br />
                  Dubai, United Arab Emirates
                  <br />

                </div>
                <br />
                <div className="map-container" style={{ position: "relative" }}>
                  {/* <div style={{height:"20px", width:"100%",backgroundColor:"#4D6A79", position:"absolute",top:"35px"}}></div> */}

                  <iframe
                    id="map-frame"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.096215017227!2d55.17188071500762!3d25.09860418394211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b70f2b53431%3A0x3948cd467fab2f48!2sSuno%201024%20Fm!5e0!3m2!1sen!2sin!4v1616844877567!5m2!1sen!2sin"
                    width="460"
                    height="480"
                  ></iframe>

                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* to show countDown modal */}
        {this.props.showCountDownModal ? (
          <div
            className={`countDown-modal justify-between
                  ${this.props.showCountDownModal ? "active" : ""}
                  `}
          >
            <div className="dropArrow" />
            <img
              onClick={() => (
                this.props.showCountDownModalAction(false),
                this.setState({ showSubItemCountDown: "" })
              )}
              className=" absolute w-6 rounded-full cursor-pointer close-icon img-fluid"
              src={Close}
              alt=""
            />
            {CountDownData.map((countDown, index) => {
              return (
                <>
                  <a
                    href={countDown.url}
                    target={countDown.samePage ? "_self" : "_blank"}
                  >
                    <div
                      className="countdown-item cursor-pointer"
                      onClick={() => this.handleCountDownClick(countDown)}
                    >
                      {countDown.heading}
                    </div>
                  </a>
                  {this.state.showSubItemCountDown === countDown.heading &&
                    countDown.subItems && (
                      <div className="subItems-holder">
                        {countDown.subItems.map((data, idx) => {
                          return (
                            <a href={data.url} target="_blank">
                              <div className="countdown-item">
                                {data.heading}
                              </div>
                            </a>
                          );
                        })}
                      </div>
                    )}
                </>
              );
            })}
          </div>
        ) : (
          ""
        )}

        <Route path="*" component={videoModal} />
        {this.props.showGlobalPlayer ? (
          <Route path="*" component={GlobalPlayer} />
        ) : (
          ""
        )}
        <Route path="*" component={Header} />
        <Suspense
          fallback={
            <div className="loader-div">
              <img className="app-loader" src={Loader} alt="loader" />
            </div>
          }
        >
          <Switch>
            {routeComponents}
            <Redirect from="/*" to="/" />
          </Switch>
        </Suspense>

        {this.state.showInstallMessage ? (
          userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) ? (
            userAgent.indexOf("Safari") !== -1 &&
            /^((?!chrome|android|crios|fxios).)*safari/i.test(userAgent) && (
              <div className="install-message-modal flex-center">
                <div className="modal-container">
                  <div className="img-container text-center mb-0">
                    <img className="logo" src={Logo} alt="icon" />
                  </div>
                  <img
                    onClick={() => this.handleInstallMessage()}
                    className=" close-icon img-fluid"
                    src={Close}
                    alt=""
                  />
                  <div className="install-text text-18 text-rd-black text-center text-black mb-5">
                  <a href="https://mirchi.onelink.me/NETn/90ffb17e" className="text-black m-0">Mirchi has a new app</a>
                  </div>
                  <div className="text-md text-support text-center mb-3 ">
                  <a href="https://mirchi.onelink.me/NETn/90ffb17e" className="text-black m-0">Install the brand new Mirchi app to access live radio, audio stories &amp; podcasts.</a>
                  </div>
                  {
                    // (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) &&
                    // // navigator.userAgent.match('CriOS') &&
                    // userAgent.indexOf("Safari") !== -1 &&
                    // /^((?!chrome|android|crios|fxios).)*safari/i.test(userAgent) &&
                    
                    // <div className="tap-share-text">
                    //   Just tap <img className="share" src={Share} alt="icon" />{" "}
                    //   then 'Add to Home Screen'
                    // </div>
                  }
                </div>
              </div>
            )
          ) : (
            <div className="install-message-modal flex-center">
              <div className="modal-container">
                <div className="img-container text-center mb-0">
                  <img className="logo" src={Logo} alt="icon" />
                </div>
                <img
                  onClick={() => this.handleInstallMessage()}
                  className=" close-icon img-fluid"
                  src={Close}
                  alt=""
                />
                <div className="install-text text-18 text-rd-black text-center text-black mb-5">
                <a href="https://mirchi.onelink.me/NETn/90ffb17e" className="text-black m-0">Mirchi has a new app</a>
                </div>
                <div className="text-md text-support text-center mb-3 ">
                <a href="https://mirchi.onelink.me/NETn/90ffb17e" className="text-black m-0">Install the brand new Mirchi app to access live radio, audio stories &amp; podcasts.</a>
                </div>
              </div>
            </div>
          )
        ) : (
          ""
        )}
      </div>
    );
  }
}

const routedComponent = withRouter(RootRouter);
export default connect(
  ({ ui, contactUs }) => ({ ...ui, ...contactUs }),
  (dispatch) =>
    bindActionCreators({ ...uiActions, ...contactUsActions }, dispatch)
)(routedComponent);
