import React from 'react';
import './rjCard.scss'
import ImageCard from "../imageCard";

export default function rjCard(props) {
  return (
    <div className={`relative  inline-block ${props.home && 'mr-3'}`}
      style={{ width: props.cardWidth, height: props.cardHeight }}>
      <ImageCard
        primary
        rjimg
        rjCard={props.rjHomeCard ? true : false}
        rjShadow={props.rjShadow ? true : false}
        src={props.src}
        alt="rj-card"
        width={props.width}
        height={props.height} />
      <div className="card-details absolute">
        <div className={`name text-2xl font-black italic text-white ${props.rjList && 'text-3xl lg:text-2xl'}`}> {props.name}</div>
      </div>
    </div>
  );
}