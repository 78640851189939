import { Api } from "./config/request";

export const getCityStationsList = () => {
  return Api.getRequest("city-station");
};
export const getCityStationsById = (id) => {
  return Api.getRequest(`city-station/${id}`);
};
export const getCityStationsBySlug = (slug) => {
  return Api.getRequest(`city-station/slug/${slug}`);
};
export const getRjDataList = payload => {
  return Api.getRequest("city-station/"+ payload.cityStationId + "/rjData/" + payload.rjDataId);
};
export const createCityStations = payload => {
    return Api.postRequest("",payload);
};
  export const updateCityStationsById = (id,payload)  => {
    return Api.putRequest(`${id}`,payload);
};
  export const deleteCityStationsById = id => {
    return Api.deleteRequest(`${id}`);
};

export const CityStations = { 
    getCityStationsList ,
    createCityStations,
    getRjDataList,
    getCityStationsById,
    getCityStationsBySlug
};
