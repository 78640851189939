import React, { Component } from "react";
import './blogs.scss';
import CardsHeader from "components/cardsHeader";
import * as BlogsState from "../../store/wp_store/actions"
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import * as uiActions from "store/ui/actions";
import { Link } from "react-router-dom";
import WPCard from '../../components/WPCard';
import BlogCardFeatured from "../../components/blogCardFeatured";
import Footer from "components/footer";

class Blogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogList: [],
            Film: [],
            Music: [],
            Humour: [],
            Trending: [],
            moreFilm: false,
            moreMusic: false,
            moreHumour: false,
        };
    }
    async componentDidMount() {
        // console.log(this.props.widthState);
        this.props.showLoader(true);
        this.props.getBlogListRequest().then((res) => {
            this.setState({
                blogList: res,
            })

            this.props.showLoader(false);

        });
        // console.log(this.props);
        var filmData = await this.props.getRelatedBlogByIdRequest(31);
        var musicData = await this.props.getRelatedBlogByIdRequest(32);
        var humourData = await this.props.getRelatedBlogByIdRequest(33);
        var trendingData = await this.props.getRelatedBlogByIdRequest(34);
        // console.log(trendingData.length);
        this.setState({
            Film: filmData || [],
            Music: musicData || [],
            Humour: humourData || [],
            Trending: trendingData || []
        })
    }
    render() {
        const moreFilms = () => this.setState({ moreFilm: true })
        const moreMusics = () => this.setState({ moreMusic: true })
        const moreHumours = () => this.setState({ moreHumour: true })
        const lessFilms = () => this.setState({ moreFilm: false })
        const lessMusics = () => this.setState({ moreMusic: false })
        const lessHumours = () => this.setState({ moreHumour: false })
        return (
            <div>
                <div className="topContent" >
                    <div style={{ padding: "3% 0", margin: "0 8%" }}>
                        <div className={this.props.widthState > 1024 ? "row" : "columm"}>

                            {/* featured */}
                            <div className="col-0">
                                {this.state.blogList && this.state.blogList.length > 0 ? (
                                    <a href={`/new-on-mirchi/${this.state.blogList[0].slug}`}>
                                        <BlogCardFeatured
                                            data={this.state.blogList[0]}
                                            type={this.state.blogList[0].categories}
                                            size="big"
                                        />
                                    </a>
                                ) : ""}

                            </div>
                            {/*rest  */}
                            <div className={this.props.widthState > 1024 ? "col-1" : "col-0"}>
                                <div className={this.props.widthState > 1024 ? "grid-container" : ""}>
                                    {this.state.blogList && this.state.blogList.length > 0 && this.state.blogList.map((post, index) => {
                                        if (index > 0 && index < 5) {
                                            return (

                                                <a href={`/new-on-mirchi/${post.slug}`}>
                                                    <BlogCardFeatured data={post}
                                                        type={this.state.blogList[index].categories[0]}
                                                        size={this.props.widthState > 1024 ? "small" : "big"} />
                                                </a>

                                            )
                                        }
                                    })}
                                    {/* {this.state.Trending && this.state.Trending.length > 0  ? (
                                        <>
                                  
                                            <BlogCard
                                                data={this.state.Trending[0]} />
                                            <BlogCard
                                                data={this.state.Trending[0]} />
                                            <BlogCard
                                                data={this.state.Trending[0]} />
                                            <BlogCard
                                                data={this.state.Trending[0]} />
                                        </>
                                    ) : ""} */}


                                </div>
                            </div>
                            {/* :""} */}
                        </div>
                    </div>
                </div>


                <div className="blog-wrapper main  lg:pt-10" style={{ height: "100%" }}>

                    <div className="lg:flex">
                        <div className="w-120" style={{ paddingRight: "25px" }}>
                            {/* film */}
                            <div style={{ paddingBottom: "0", paddingLeft: "2%" }}>
                                <CardsHeader heading="FILM" headtype="h2" ></CardsHeader>
                            </div>
                            <div>
                                {this.state.Film && this.state.Film.length > 0 && this.state.Film.map((post, index) => {
                                    if (index < 2) {
                                        return (
                                            <Link
                                                to={`/new-on-mirchi/${post.slug}`}
                                            >
                                                <WPCard data={post} type="Films" key={index} />
                                            </Link>
                                        )
                                    }
                                })}
                            </div>

                            {!this.state.moreFilm && this.state.Film.length > 2 ?
                                (<div>
                                    <button className="btnMore" onClick={moreFilms}>More</button>
                                </div>) : ""
                            }

                            {this.state.moreFilm && this.state.Film && this.state.Film.length > 0 && this.state.Film.map((post, index) => {
                                if (index >= 2) {
                                    console.log(post);
                                    return (
                                        <Link
                                            to={`/new-on-mirchi/${post.slug}`}
                                        >
                                            <WPCard data={post} type="Films" key={index} />
                                        </Link>
                                    )
                                }
                            })}

                            {this.state.moreFilm && this.state.Film.length > 2 ?
                                (<div>
                                    <button className="btnMore" onClick={lessFilms}>Less</button>
                                </div>) : ""
                            }


                            {/* music */}
                            <div style={{ paddingBottom: "0", paddingLeft: "2%" }}>
                                <CardsHeader heading="MUSIC" headtype="h2"></CardsHeader>
                            </div>
                            <div>
                                {this.state.Music && this.state.Music.length > 0 && this.state.Music.map((post, index) => {
                                    if (index < 2) {
                                        return (
                                            <Link
                                                to={`/new-on-mirchi/${post.slug}`}
                                            // params={{ BlogTitle: post.id }}
                                            >
                                                <WPCard data={post} type="Music" key={index} />
                                            </Link>
                                        )
                                    }
                                })}
                            </div>

                            {!this.state.moreMusic && this.state.Music.length > 2 ?
                                (<div>
                                    <button className="btnMore" onClick={moreMusics}>More</button>
                                </div>) : ""
                            }

                            {this.state.moreMusic && this.state.Music && this.state.Music.length > 0 && this.state.Music.map((post, index) => {
                                if (index >= 2) {
                                    return (
                                        <Link
                                            to={`/new-on-mirchi/${post.slug}`}
                                        // params={{ BlogTitle: post.id }}
                                        >
                                            <WPCard data={post} type="Music" key={index} />
                                        </Link>
                                    )
                                }
                            })}

                            {this.state.moreMusic && this.state.Music.length > 2 ?
                                (<div>
                                    <button className="btnMore" onClick={lessMusics}>Less</button>
                                </div>) : ""
                            }

                            {/* humour */}
                            <div style={{ paddingBottom: "0", paddingLeft: "2%" }}>
                                <CardsHeader heading="HUMOUR" headtype="h2"></CardsHeader>
                            </div>
                            <div>
                                {this.state.Humour && this.state.Humour.length > 0 && this.state.Humour.map((post, index) => {
                                    if (index < 2) {
                                        return (
                                            <Link
                                                to={`/new-on-mirchi/${post.slug}`}
                                            // params={{ BlogTitle: post.id }}
                                            >
                                                <WPCard data={post} type="Humour" key={index} />
                                            </Link>
                                        )
                                    }
                                })}
                            </div>

                            {!this.state.moreHumour && this.state.Humour.length > 2 ?
                                (<div>
                                    <button className="btnMore" onClick={moreHumours}>More</button>
                                </div>) : ""
                            }

                            {this.state.moreHumour && this.state.Humour && this.state.Humour.length > 0 && this.state.Humour.map((post, index) => {
                                if (index >= 2) {
                                    return (
                                        <Link
                                            to={`/new-on-mirchi/${post.slug}`}
                                        // params={{ BlogTitle: post.id }}
                                        >
                                            <WPCard data={post} type="Humour" key={index} />
                                        </Link>
                                    )
                                }
                            })}

                            {this.state.moreHumour && this.state.Humour.length > 2 ?
                                (<div>
                                    <button className="btnMore" onClick={lessHumours}>Less</button>
                                </div>) : ""
                            }

                            {/* podcast */}
                            {/* <CardsHeader heading="Podcast" headtype="h2"></CardsHeader>
                            <div>
                                {this.state.Podcast && this.state.Podcast.length > 0 && this.state.Podcast.map((post, index) => {
                                    if(index<2){        
                                        return(    
                                            <Link
                                                to={`/blogs/${post.slug}`}
                                            // params={{ BlogTitle: post.id }}
                                            >
                                                <WPCard data={post} type="Podcast" key={index} />
                                            </Link>  
                                        )
                                    }                                  
                                })}
                            </div>

                            {!this.state.morePodcast && this.state.Podcast.length > 2 ?
                            (<div>
                                <button className="btnMore" onClick={morePodcasts}>More</button>
                            </div>) : ""
                            }

                            {this.state.morePodcast && this.state.Podcast && this.state.Podcast.length > 0 && this.state.Podcast.map((post, index) => {
                                if(index<2){        
                                    return(    
                                        <Link
                                            to={`/blogs/${post.slug}`}
                                        // params={{ BlogTitle: post.id }}
                                        >
                                            <WPCard data={post} type="Podcast" key={index} />
                                        </Link>  
                                    )
                                }                                  
                            })} */}

                        </div>

                        {/* File Review  */}
                        {/* <div className={` ${this.props.widthState >= 836 ? "w-50" : "w-100"}`} >
                            <CardsHeader heading="FILM REVIEW" headtype="h2"></CardsHeader>
                            <div className=" miniBlog">
                                <div className="blogImg">
                                    <img src="https://images.unsplash.com/photo-1508919801845-fc2ae1bc2a28?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aW1nfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80" alt="blogPost" />
                                </div>
                                <div className="blog-about">
                                    <h3 className="desc">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo inventore itaque cumque ipsa dolorum vero, </h3>
                                </div>
                            </div>

                            // Contition if more than 2 
                            <div>
                                <button className="btnMore-mini">More</button>
                            </div>

                        </div> */}


                    </div>

                </div>
                <Footer />
            </div>
        );
    };
}
export default connect(
    ({ blogs, ui }) => ({ ...blogs, ...ui }),
    dispatch => bindActionCreators({ ...BlogsState, ...uiActions }, dispatch)
)(Blogs);