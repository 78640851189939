import { Pictures } from "../../services/pictures";
import { mutation } from "./mutations";

const handleError = err => {
  console.log("Error in pictures action : ", err);
};

// Actions
export const getPicturesList = () => async dispatch => {
  try {
    let result = await Pictures.getPicturesList();
    console.log("IMAGES ",result);
    dispatch(mutation.setPicturesList(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};
