import React, {Component} from 'react';
import './videoModal.scss'
import Close from "static/images/cross.svg";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as uiActions from "../../store/ui/actions";

class videoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  closeVideoModal = () => {
    this.props.openVideoPlayerModal(false, null);
  };
  
  onIframeLoad = () => {
    this.props.showLoader(false);
  };
  
  
  render() {
    return <div className={`component-youtube-modal
     ${this.props.videoData && Object.keys(this.props.videoData).length && 'active' }`}>
      {this.props.videoData ?
        <div className={`iframe-container flex items-center   justify-center relative w-full h-full`}>
          <img src={Close} className={`close-icon absolute cursor-pointer`} onClick={this.closeVideoModal}/>
          <iframe
            width="90%"
            height="90%"
            id={`iframe${this.props.currentRank}`}
            src={this.props.videoData.embedLink}
            className={`videoModal video-player`}
            title={this.props.videoData.title}
            frameBorder="0"
            onLoad={this.onIframeLoad}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        : ''}
    </div>;
  }
}

export default connect(
  ({ui}) => ({...ui}),
  dispatch => bindActionCreators({...uiActions}, dispatch)
)(videoModal);
