import React, {Component} from "react";
import "./movieCard.scss";
import ImageCard from "../imageCard";
import UpArrow from "static/images/up-arrow.png";
import DownArrow from "static/images/down-arrow.png";
import Star from "static/images/star.svg";
import PlayButton from "static/images/play-button.svg";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as uiActions from "../../store/ui/actions";

class movieCard extends Component {
  
  openYoutubeVideo = async props => {
    // if (window.innerWidth < 1024) {
    //   window.open(props.video);
    //   return 1;
    // }
    this.props.showLoader(true);
    
    const data = {
      link: props.video,
      embedLink: `${props.embedVideo}?rel=0&autoplay=1&mute=1`,
      thumbnail: props.src,
      key: props.currentRank,
      index: props.currentRank,
      title: props.title
    };
    await this.props.openVideoPlayerModal(true, data);
  };
  
  render() {
    
    return (
      <div
        className={`movie-card-holder w-full inline-block  ${
          this.props.top20 ? "flex items-center mb-0" : "mb-4"
          }`}
        onClick={() => this.openYoutubeVideo(this.props)}
      >
        <div
          className={`img-container relative ${this.props.top20Listing && 'top20Listing'} ${this.props.sideBar && 'sideBar'}`}>
          <ImageCard
            movieCard
            stationDetail
            top20={this.props.top20}
            mirchiTop20={true}
            active={this.props.active}
            src={this.props.src}
            alt="rj-card"
            className="w-full"
            width={this.props.width}
            height={this.props.height}
          />
          {!this.props.sideBar ? <img
            className="video-icon play"
            
            src={PlayButton}
            alt=""
          /> : ''}
          {/*  {this.props.sideBar ? <ImageCard*/}
          {/*    movieCard*/}
          {/*    stationDetail*/}
          {/*    top20={this.props.top20}*/}
          {/*    mirchiTop20={true}*/}
          {/*    active={this.props.active}*/}
          {/*    src={this.props.src}*/}
          {/*    alt="rj-card"*/}
          {/*    className="w-full"*/}
          {/*    width={this.props.width}*/}
          {/*    height={this.props.height}*/}
          {/*  />:   <VideoCard*/}
          {/*  cardWidth="100%"*/}
          {/*  height="265px"*/}
          {/*  top20Listing*/}
          {/*  link={this.props.video}*/}
          {/*  thumbnail={this.props.src}*/}
          {/*  key={this.props.currentRank}*/}
          {/*  index={this.props.currentRank}*/}
          {/*/> }*/}
          
          <div
            className={`ranking-container absolute ${this.props.homeCard && 'home-movie-card'} ${this.props.top20 && 'top20-card'}`}>
            <div
              className={`ranking-holder relative  w-16 h-16 text-4xl flex items-center font-black italic leading-none rounded-full ${this.props.active && 'scale-img'}`}>
              {this.props.currentRank < 10 ? this.props.currentRank : this.props.currentRank}
              
              {this.props.changingRank > 0 ?
                <div className="rank positive-rank flex items-center py-1 px-1 text-sm text-white absolute">
                  <img src={UpArrow} style={{width: "10px"}} alt="up-arrow"/>
                  {this.props.changingRank < 10 ? '0' + this.props.changingRank : this.props.changingRank}
                </div>
                : this.props.changingRank < 0 ?
                  <div className="rank negative-rank flex items-center py-1 px-1 text-sm text-white absolute">
                    <img style={{height: "10px"}} src={DownArrow} alt="down-arrow"/>
                    {this.props.changingRank > -10 ? '0' + Math.abs(this.props.changingRank) : Math.abs(this.props.changingRank)}
                  </div> : ''
              }
              {this.props.lastWeekPosition.toLowerCase() === 'new' && this.props.changingRank.toLowerCase() === 'new' ?
                <div className="rank new flex items-center py-1 px-1 text-sm text-white absolute">
                  <img style={{height: "10px", marginRight: "2px"}} src={Star} alt="star"/>
                  New
                </div> : ''}
            </div>
          </div>
        </div>
        <div className="movie-details ">
          <div
            className={`text-white text-center leading-none text-lg lg:mb-1 lg:text-base mb-2 capitalize ${this.props.top20 && 'top20-desktop'}`}>
            {this.props.songName}
          </div>
          <div
            className={`movie-name text-base capitalize lg:text-xs ${
              this.props.top20 ? "text-left" : "text-center"} leading-none `}
          >
            {this.props.movieName}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ui}) => ({...ui}),
  dispatch => bindActionCreators({...uiActions}, dispatch)
)(movieCard);
