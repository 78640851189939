import { Contest } from "../../services/contest";
import { mutation } from "./mutations";

const handleError = err => {
  console.log("Error in Contest action : ", err);
};

export const getContestListRequest = () => async dispatch => {
  try {
    let result = await Contest.getContestList();
    dispatch(mutation.setContestList(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};
export const getContestDetailRequest = (id) => async dispatch => {
  try {
    let result = await Contest.getContestDetail(id);
    dispatch(mutation.setActiveContest(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

