import { initialState } from "./state";

export const sponsors = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SPONSORS_LIST": {
      return Object.assign({}, state, {
        sponsorsList: action.data
      });
    }
    default:
      return state;
  }
};
