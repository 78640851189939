import { initialState } from "./state";

export const WP_Reducer = (state = initialState, action) => {
  switch (action.type) {
    // case "SET_NEWS_CAT_LIST": {
    //   return Object.assign({}, state, {
    //     newsCategories: state.newsCategories.concat(action.data)
    //   });
    // }
    // case "SET_CAT_NEWS_LIST": {
    //   return Object.assign({}, state, {
    //     catNewsList: state.catNewsList.concat(action.data)
    //   })
    // }
    // case "ADD_NEWS_LIST": {
    //   return Object.assign({}, state, {
    //     newsList: state.newsList.concat(action.data)
    //   })
    // }
    // case "UNSET_NEWS_LIST": {
    //   return Object.assign({}, state, {
    //     newsList: []
    //   })
    // }
    // case "SET_NEWS_LIST": {
    //   return Object.assign({}, state, {
    //     newsList: action.data
    //   });
    // }
    // case "SET_ACTIVE_NEWS": {
    //   return Object.assign({}, state, {
    //     activePost: action.data
    //   })
    // }
    case "SET_BLOG_LIST": {
      return Object.assign({}, state, {
        blogs: action.data
      })
    }

    case "SET_BLOG_DETAILS": {
      return Object.assign({}, state, {
        blogDetails: action.data
      })
    }

    case "SET_BLOG_AUTHOR": {
      return Object.assign({}, state, {
        author: action.data
      })
    }

    case "SET_BLOG_CATEGORY": {
      return Object.assign({}, state, {
        category: action.data
      })
    }

    case "SET_BLOG_MEDIA": {
      return Object.assign({}, state, {
        media: action.data
      })
    }

    case "SET_RELATED_BLOG": {
      return Object.assign({}, state, {
        relatedBlog: action.data
      })
    }
    default:
      return state;
  }
};
