import {Home} from "../../services/home";
import {mutation} from "./mutations";
import {mutation as rjMutations} from "../rj/mutations";
import {mutation as stationMutations} from "../cityStations/mutations";
import {mutation as eventMutations} from "../events/mutations";

const handleError = err => {
  console.log("Error in home action : ", err);
};

// Actions
export const getHomeData = () => async dispatch => {
  try {
    let result = await Home.requestHomeData();
    dispatch(mutation.setHomeList(result));
    dispatch(rjMutations.setRjList(result['rjs']));
    dispatch(stationMutations.setCityStationsList(result['cityStation']))
    dispatch(eventMutations.setEventsList(result['event']))
    
    
  } catch (err) {
    handleError(err);
  }
};

export const getHomeSlider = () => async dispatch => {
  try {
    let result = await Home.homeSlider();
    dispatch(mutation.setHomeSlider(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

