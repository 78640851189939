import React, { Component } from "react";
import '../blogs.scss';
import CardsHeader from "components/cardsHeader";
import * as uiActions from "store/ui/actions";
import Footer from "components/footer";
import FacebookColored from "static/images/facebook-colored.svg";
import TwitterColored from "static/images/twitter-colored.svg";
import * as BlogsState from "../../../store/wp_store/actions"
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import BlogCardMini from '../../../components/blogCardMin';
import { Link } from "react-router-dom";
import { validS3Url } from "../../../services/config/request";

class BlogsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogDetials: [],
            relatedBlogs: [],
            author: null,
            media: null,
            category: "",
            moreRelatedBlog: false,
            isLoading: false
        }
    }
    async componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
        let id = this.props.match.params.slug;

        if (!this.props.getRelatedBlogByIdRequest || this.props.getRelatedBlogByIdRequest.length == 0) {
            this.state.isLoading = true;
            var relatedBlog = await this.props.getRelatedBlogByIdRequest(31); //.then((res1) => {
            var fac = relatedBlog.find(o => o.slug == id);

            if ( !fac) {
                relatedBlog = await this.props.getRelatedBlogByIdRequest(32);
                ac = relatedBlog;
                fac = ac.find(o => o.slug == id);
                if(!fac){
                    relatedBlog = await this.props.getRelatedBlogByIdRequest(33);
                    ac = relatedBlog;
                    fac = ac.find(o => o.slug == id);
                    if(!fac){
                        relatedBlog = await this.props.getRelatedBlogByIdRequest(34);
                        ac = relatedBlog;
                        fac = ac.find(o => o.slug == id);
                    }
                }
            }
            var blogdetial = await this.props.getBlogByIdRequest(fac.id);
            var author = await this.props.getAuthorByIdRequest(fac.author);
            var media = await this.props.getMediaByIdRequest(fac.featured_media);
            var category = await this.props.getCategoryByIdRequest(fac.categories[0]);
            this.setState({
                blogDetials: blogdetial,
                relatedBlogs: relatedBlog,
                author: author,
                media: media.media_details.sizes["full"].source_url,
                category: category.name,
                isLoading: false
            });
            // console.log(this.state);

        }
        else {
            var ac = this.props.blogList;
            var fac = ac.find(o => o.slug == id);
            this.state.isLoading = true;
            this.props.getBlogByIdRequest(fac.id)
                .then((res) => {
                    console.log(res)
                    this.setState({ blogDetials: res, isLoading: false });
                    console.log(`test - ${this.state.blogDetials}`);
                })
        }

    }

    render() {
        const moreRelatedBlogs = () => this.setState({ moreRelatedBlog: true })
        const lessRelatedBlogs = () => this.setState({ moreRelatedBlog: false })
        function month_name(dt) {
            var mlist = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            return mlist[dt];
        };
        var dateObj = this.state.blogDetials.date
        let dateConv = new Date(dateObj);
        let dateMDY = `ON ${month_name(dateConv.getMonth() + 1)} ${dateConv.getDate()}, ${dateConv.getFullYear()}`;

        if (!this.state.isLoading) {
            return (
                <div >

                    <div className="blog-wrapper main  lg:pt-10 blog" >

                        <div className="lg:flex" style={{ marginTop: "4%" }}>

                            <div className={`blog-container w-120 pr`}>

                                <p style={{ marginBottom: "5%" }}>
                                    <a href="/new-on-mirchi">Home</a>
                                    <span style={{ height: " 4px", width: "4px", margin: "3px", backgroundColor: "red", borderRadius: "50%", display: "inline-block" }}></span>
                                    {/* <span className="breadcrumbs_delimiter"></span> */}
                                    {this.state.category}
                                </p>
                                <div className="titleHeading"
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.blogDetials.title.rendered
                                    }}
                                ></div>

                                <div className="headerdes"
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.blogDetials.excerpt.rendered
                                    }}
                                ></div>
                                <div className="blogFlex" style={{ marginTop: "3%", marginBottom: "6%" }}>

                                    <p className="author" style={{ fontSize: "12px" }}>{dateMDY}<br />By {this.state.author.name}</p>
                                    <div style={{ display: "flex", flexDirection: "row", paddingTop: "10px" }}>
                                        <a
                                            href="https://www.facebook.com/mirchiuae"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="relative"
                                        >
                                            <img
                                                src={FacebookColored}
                                                alt="facebook"
                                                className="h-8 w-8 mr-5"
                                            />
                                        </a>

                                        <a
                                            href="https://www.twitter.com/mirchiuae"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="relative"
                                        >
                                            <img
                                                src={TwitterColored}
                                                alt="Twitter"
                                                className="h-8 w-8 mr-5"
                                            />
                                        </a>
                                    </div>
                                </div>

                                <div className="reusableBlog">
                                    <div className="blogBannerImg">
                                        <img src={validS3Url(this.state.media)} alt="blogPost" />
                                    </div>
                                    <div className="blogDescription"
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.blogDetials.content.rendered,
                                        }}
                                    >
                                    </div>
                                </div>
                            </div>

                            <div className={`blog-container ${this.props.widthState >= 836 ? "w-50" : "w-100"}`} >
                                <CardsHeader heading="Best of mirchi play" headtype="h2"></CardsHeader>


                                <div>
                                    {this.state.relatedBlogs && this.state.relatedBlogs.length > 0 && this.state.relatedBlogs.map((post, index) => {
                                        if (index < 2) {
                                            return (

                                                <a href={`/new-on-mirchi/${post.slug}`}>
                                                    <BlogCardMini data={post} type="Film" key={index} />
                                                </a>

                                            )
                                        }
                                    })}
                                </div>

                                {!this.state.moreRelatedBlog && this.state.relatedBlogs.length > 2 ?
                                    (<div>
                                        <button className="btnMore-mini" onClick={moreRelatedBlogs}>More</button>
                                    </div>) : ""
                                }

                                {this.state.moreRelatedBlog && this.state.relatedBlogs && this.state.relatedBlogs.length > 0 && this.state.relatedBlogs.map((post, index) => {
                                    if (index >= 2) {
                                        return (
                                            <Link
                                                to={`/new-on-mirchi/${post.slug}`}
                                            // params={{ BlogTitle: post.id }}
                                            >
                                                <BlogCardMini data={post} type="Film" key={index} />
                                            </Link>
                                        )
                                    }
                                })}

                                {this.state.moreRelatedBlog && this.state.relatedBlogs.length > 2 ?
                                    (<div>
                                        <button className="btnMore-mini" onClick={lessRelatedBlogs}>Less</button>
                                    </div>) : ""
                                }
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div >
            )
        }

        return null;


    }
}
export default connect(
    ({ blogDetials, ui }) => ({ ...blogDetials, ...ui }),
    dispatch => bindActionCreators({ ...BlogsState, ...uiActions }, dispatch)
)(BlogsPage);