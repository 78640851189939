import { Api } from "./config/request";

export const requestHomeData = () => {
  return Api.getRequest("home/");
};

export const homeSlider = () => {
  return Api.getRequest('ad-slider/');
}

export const Home = {
  requestHomeData,
  homeSlider
};
