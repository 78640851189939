import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as uiActions from "store/ui/actions";
import * as contactUsActions from "store/contactUs/actions";
import './contactUs.scss';
import UAEMirchiLogo from "static/images/uae-logo.jpg";
import UAEMirchiLogoContactus from "static/images/mirchi4.png";


class ContactUs extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            message: '',
            station: '',
            response: '',
            loading: false,
            error: ''
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.adclick = this.adclick.bind(this);
    }
    adclick = () => {
        window.open('mailto:info@mirchione.com');
    }
    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        console.log(value);

        this.setState({
            [name]: value
        });
    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            loading: true
        })
        this.props.sendEmail({name: this.state.name, email: this.state.email, phone: this.state.phone, message: this.state.message, station: this.state.station}).then(res => {
            this.setState({
                name: '',
                email: '',
                phone: '',
                message: '',
                station: '',
                response: true,
                loading: false
            })
        })
        return false;
    }
    render(){
        return (<div>
            <div className="component-contact-us">
                <div className="contact-us-container">
                    <div className="top-container flex">
                        <div className="top-header-contact-us lg:pl-8">
                        <img src="https://staging.radiomirchiusa.com/static/media/mirchi.0043cc7b.png" className="mirchi-img" alt="mirchi" />
                        {/* <img src={UAEMirchiLogoContactus} className="mirchi-img" alt="mirchi" /> */}
                        <div className="welcom-txt">
                            <img
                            className="img-fluid logo desktop-img hidden-tablet"
                            src={UAEMirchiLogo}
                            alt="logo"
                            />
                            <div>
                            <div className="text-hero">
                                SOUTH ASIA’S NO.1 RADIO STATION,
                            </div>
                            <div className="text-hero-bottom">NOW IN THE UAE!</div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="component-contact px-5 pb-5 pt-24 lg:pt-10 lg:pl-8">
                <h1 className="text-34">Contact Us</h1>
            </div>
            <div className="component-contact pb-5">
                <div className="px-5 pb-5 lg:pl-8">
                    <p className="mt-3">We’re here to help you grow your audience customer base, </p>
                    <p className="mt-3">Call us on <a href="tel:+971-44534950">+971-44534950</a>, E-mail us by clicking on <a href="mailto:sales@mirchi.ae">sales@mirchi.ae</a></p>
                </div>
                <div className="px-5 pb-5 pt-24 lg:pt-10 lg:pl-8">
                    <h1 className="text-34">Let’s Talk. But You First.</h1>
                    
                    <form className="mt-10" onSubmit={this.handleSubmit}>
                        <div className="d-block mb-10">
                            <label className="">Name *</label>
                            <input type="text" name="name" value={this.state.name} onChange={this.handleChange} />
                        </div>
                        <div className="d-block mb-10">
                            <label className="labelData">Email Address *</label>
                            <input type="text" name="email" value={this.state.email} onChange={this.handleChange} />
                        </div>
                        <div className="d-block mb-10">
                            <label className="labelData">Mobile Phone *</label>
                            <input type="text" name="phone" value={this.state.phone} onChange={this.handleChange} />
                        </div>
                        
                        <div className="d-block mb-10">
                            <label>Message *</label>
                            <textarea type="text" name="message" value={this.state.message} onChange={this.handleChange}></textarea>
                        </div>
                        <button disabled={this.state.loading} type="submit">Submit</button>
                    </form>
                    {
                        this.state.response ? (
                            <p className="mt-10">Your details were successfully submitted. Please allow us to reach out to you.</p>
                        ) : (
                            this.state.error && (
                                <p>Your enquiry submission faild.</p>
                            )
                        )
                    }
                </div>
                <div className="px-5 pb-5 pt-24 lg:pt-10 lg:pl-8">
                    <h1 className="text-34">Find Us</h1>
                    <div>
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39106.50370844268!2d55.235136159484505!3d25.216909163817014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f42f9b10fb7c7%3A0xdb206f3bf56dca8b!2sDolphin%20Recording%20Studio%20LLC!5e0!3m2!1sen!2sin!4v1627522673548!5m2!1sen!2sin" width="100%" height="450"></iframe> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d253952.16219872152!2d55.03020872490892!3d25.08356529100553!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b70f2b53431%3A0x3948cd467fab2f48!2sSuno%201024%20Fm!5e0!3m2!1sen!2sin!4v1633514579533!5m2!1sen!2sin" width="100%" height="450" ></iframe>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default connect(
    ({ contactUs, ui }) => ({ ...contactUs, ...ui }),
    dispatch => bindActionCreators({ ...contactUsActions, ...uiActions }, dispatch)
)(ContactUs);