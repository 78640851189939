import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as StateionActions from "../../store/onlineStations/actions";
import CardsHeader from "components/cardsHeader";
import Slider from "react-slick";
import "./index.scss";
import * as PicturesActions from "../../store/pictures/actions";
import Modal from "react-modal";
import { validS3Url } from "../../services/config/request";

class Pictures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      showModal: false,
      currentPictureUrl: "",
      showPicturesModal: false,
    };
  }

  async componentDidMount() {
    await this.props.getOnlineStationsListRequest();
    await this.props.getPicturesList();
    console.log(this.props);
    console.log(this.state);
  }

  showImageInModal(index) {
    this.setState({
      currentImage: index,
      showModal: false,
    });
  }
  onCloseModal() {
    this.setState({
      showModal: false,
    });
  }
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
    };
    return (
      <div
        // style={{
        //   padding: "70px",
        // }}
        className="wrapper pictures-page"
      >
        <Modal
          isOpen={this.state.showPicturesModal}
          style={{
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            content: {
              width: "500px",
              height: "530px",
              marginTop: "50px",
              marginLeft: "50%",
              transform: "translateX(-50%)",
              paddingBottom: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            },
          }}
          onRequestClose={() => {
            this.setState({
              showPicturesModal: false,
            });
          }}
        >
          <button
            style={{
              position: "absolute",
              top: "21px",
              left: "20px",
            }}
            onClick={() => this.setState({ showPicturesModal: false })}
          >
            x
          </button>
          <img
            src={validS3Url(this.state.currentPictureUrl)}
            style={{
              transform: "",
              width: "400",
              objectFit: "contain",
              height: "256px",
              borderRadius: "15px",
              marginTop: "15px",
            }}
          />
        </Modal>
        <div>
          <CardsHeader
            showArrows={
              !!(
                this.props.onlineStationsList &&
                this.props.onlineStationsList.length > 4
              )
            }
            noViewAll
            heading="Mirchi Pictures"
            headtype="h1"
            subheading="Pictures of Mirchi RJs"
          />

          {this.props.pictures.pictures.map((pic, index) => (
            <a>
              <img
                className="inline-block align-top text-base tracking-normal mr-3"
                src={validS3Url(pic.image)}
                title={pic.title}
                alt={pic.altText}
                style={{
                  width: "188px",
                  height: "158px",
                  objectFit: "cover",
                  marginBottom: "10px",
                  borderRadius: "15px",
                }}
                onClick={() =>
                  this.setState({
                    showPicturesModal: true,
                    currentPictureUrl: pic.image,
                  })
                }
              />
              <div
                style={{
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  marginTop: "10px",
                  width: "188px",
                }}
              >
                <h3>{pic.title}</h3>
                <p
                  style={{
                    fontWeight: "100",
                    fontSize: "13px",
                    marginTop: "10px",
                    color: "#888787",
                    textAlign: "justify",
                  }}
                >
                  {pic.description}
                </p>
              </div>
            </a>
          ))}
        </div>
      </div>
    );
  }
}

export default connect(
  ({ onlineStations, pictures }) => ({ ...onlineStations, pictures: pictures }),
  (dispatch) =>
    bindActionCreators({ ...StateionActions, ...PicturesActions }, dispatch)
)(Pictures);
