import { Api } from "./config/request";

export const requestSponsorsData = async () => {
  try {
    return Api.getRequest("logo/");
  } catch (err) {
    return err;
  }
  
};

export const Sponsors = {
  requestSponsorsData
};
