import React, { Component } from "react";
import "./videoCard.scss";
import { Player, ControlBar } from "video-react";
import PlayButton from "static/images/play-button.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as videoActions from "../../store/videos/actions";
import * as uiActions from "../../store/ui/actions";
import { validS3Url } from "../../services/config/request";
// import PauseIcon from "static/images/pause-circle.svg";

class VideoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
      sourceUrl: "",
      playVideo : true,
      imgurl: "",
      showimg: false
    };
  }
  componentWillReceiveProps(nextProps){
    if (this.props.audioplay !== nextProps.audioplay) {
      if(nextProps.audioplay){
        // this.setState({playVideo : false})
        this.player.pause();
      }
    }
    if(nextProps.playedVideoData) {
      console.log(nextProps.playedVideoData);
      if (this.props.link !== nextProps.playedVideoData.videoLink) {
          this.player.pause();
      }
    }
  }
  
  componentDidMount() {
    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if(!this.state.sourceUrl) {
              this.setState({sourceUrl: this.props.link, imgurl: this.props.thumbail, showimg: true});
              if(this.player) this.player.load();
            }
          }
        });
      }
    );
    if(this.player && this.player.manager && this.player.manager.rootElement) {
      this.observer.observe(this.player.manager.rootElement);
    }
    
  }
  
  handleIframe = (event, index) => {
    document.getElementById(`iframe${index}`).style.display = "block";
    document.getElementById(`iframe${index}`).src += "?rel=0&autoplay=1&mute=1";
    document.getElementById(`iframe${index}`).style.visibility = "visible";
    document.getElementById(`iframe${index}`).allow += "autoplay;";
    document
      .getElementById(`iframe${index}`)
      .parentElement.querySelector(".video-icon.play").style.display = "none";
  };

  isiOSMobile = () => {
    return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent;
  };

  handleVideoClick = (index) => {
    this.props.pauseAudioPlayer(false);
    // console.log("this.props", this.props)
    let elem;
    // elem = document.getElementById(`${this.player.props.id}`);
    if(this.player && this.player.props) {
      elem = document.getElementById(`${this.player.props.id}`);
    } else {
      elem = document.getElementById(`player${index}`);
    }
    elem.parentElement.parentElement.classList.add("play");
    elem.parentElement.parentElement.classList.remove("pause");
    this.props.pauseOtherVideos({videoTitle: this.props.title, videoLink: this.props.link})
  };
  
  

  onClickFullscreen = index => {
    try {
      let elem ;
      if(this.player && this.player.props) {
        elem = document.getElementById(`${this.player.props.id}`);
        if (!this.isiOSMobile () && !this.player.getState().player.isFullscreen) {
          this.player.toggleFullscreen();
        }
      } else {
        elem = document.getElementById(`player${index}`);
      }
      try {
        this.setState({showimg: false})
        this.player.play();
      } catch (err) {
        console.log("error", err);
      }
      
      elem.parentElement.parentElement.classList.add("play");
      elem.parentElement.parentElement.classList.remove("pause");
    } catch (err) {
      console.log("error", err);
    }
  };
  
  render() {
    return (
      <div className={`component-video-card component-card  inline-block align-top text-base tracking-normal]
      player audio-player player-container video-player w-3/4 lg:w-full
      ${this.props.listingPage ? 'listing-page' : ''}
       ${this.props.cardCenter ? 'mx-auto' : 'mr-3'}`} style={{ width: this.props.cardWidth, height: this.props.height}}>
        { this.props.link && this.props.link.indexOf("youtu") === -1 ? (
          <div
            onClick={()=>this.handleVideoClick(this.props.index)}
            className={`relative video-container pause
            ${this.props.listingPage ? 'listing-page' : ''}
             ${this.props.cardWidth === "100%" ? '' : 'mr-3'}`}
            ref={`video-container-${this.props.index}`}
            id={`video-container-${this.props.index}`}
          >
            
            { this.isiOSMobile() ?
              <div className="relative">
                <video autobuffer={true}
                  controls={true}
                  controlsList="nodownload"
                  id={`player${this.props.index}`}
                  ref={player => {
                    this.player = player;
                  }}
                  src={this.props.link}
                  className={`react-player video ${this.props.listingPage ? 'listing-page' : ''}`}
                  width={this.props.coverVideo ? "100%" : "375"} height="375" >
                
                </video>
              </div>
              :
              <Player
                ref={player => {
                  this.player = player;
                }}
                playsInline
                id={`player${this.props.index}`}
                className={`react-player video ${this.props.listingPage ? 'listing-page' : ''}`}
              >
                <source src={validS3Url(this.state.sourceUrl)} />
                <img className={this.state.showimg ? 'tum-display' : 'd-none'} src={validS3Url(this.state.imgurl)} /> 
                <img
                  onClick={() => this.onClickFullscreen(this.props.index)}
                  className="video-icon play"
                  src={PlayButton}
                  alt=""
                />
                <ControlBar autoHide={false} className="my-class" />
              </Player>
            }
          </div>
        ) : (
            <div
              className={`relative video-container pause iframe-container
              ${this.props.top20Listing ? 'top20': ''  }
               ${this.props.coverVideo ? 'h-64 rounded-none' : 'h-48'}`}
              ref={`video-container-${this.props.index}`}
              id={`video-container-${this.props.index}`}
              style={{
                backgroundImage: `url(https://img.youtube.com/vi/' +
                  this.props.link.split("/")[
                  this.props.link.split("/").length - 1
                  ] + '/hqdefault.jpg)`
              }}
            >
              <iframe
                width="100%"
                height={this.props.height ? this.props.height: "100%"}
                id={`iframe${this.props.index}`}
                src={this.props.link}
                className={`react-player video iframe-hidden ${this.props.top20Listing ? 'top20': ''}`}
                title={this.props.title}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
              
              <img
                onClick={e => this.handleIframe(e, this.props.index)}
                className="video-icon play"
                src={PlayButton}
                alt=""
              />
            </div>
          )
        }
        {this.props.title ? <div className={`w-full flex flex-col my-4 items-center ${this.props.cardWidth === "100%" ? 'px-5' : ''}`}>
          <div className="flex items-center w-full">
            {this.props.showPlayBtn ? <div className="pr-2"><img src={require('../../static/images/play-button.svg')} alt="play" /></div> : ''}
            <div className="video-title mr-3 font-medium whitespace-normal leading-tight text-left">
              {this.props.title}
            </div>
          </div>
        </div>  : ''}
      </div>
    );
  }
}

export default connect(
  ({ui}) => ({
    ...ui
  }),
  dispatch =>
    bindActionCreators(
      {...uiActions},
      dispatch
    )
)(VideoCard);
