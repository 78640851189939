import { MirchiTop20 } from "../../services/mirchiTop20";
import { mutation } from "./mutations";
import {storage as LocalStorage} from "../../services/config/storage";

const handleError = err => {
  console.log("Error in mirchiTop20 action : ", err);
};

// Actions
export const getMirchiTop20ListRequest = () => async dispatch => {
  try {
    let result = await MirchiTop20.requestMirchiTop20Data();
    result.data.countdown = result.data.countdown.map(data => {
      data.embedVideo = `https://www.youtube.com/embed/${
        data.video.split("?v=")[1]
      }`;
      return data;
    });
    dispatch(mutation.setMirchiTop20List(result.data));
    LocalStorage.set.mirchiTop20(result.data);
  } catch (err) {
    handleError(err);
  }
};
