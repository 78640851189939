import React, { Component } from "react";
import "./headerMenu.scss";
import HeaderData from "data/menuData.js";
import { NavLink } from "react-router-dom";
import Mail from "static/images/mail1.svg"
import Phone from "static/images/phone1.svg"
import * as contactUsActions from "store/contactUs/actions"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Tiktok from "static/images/tiktok.svg";

class headerMenu extends Component {
  render() {
    return (
      <div className={`header-menu-wrap ${this.props.visible ? "active" : ""}`} onClick={this.props.closeMenu}>
        <div
          className={`component-header-menu flex flex-col justify-between ${this.props.visible ? "active" : ""
            }`}
        >
          <div className="header-menu">
            {HeaderData.map((data, i) => {
              return (
                <NavLink
                  className="header-text font-bold"
                  key={i}
                  to={data.link}
                  exact={true}
                  activeClassName="active"
                  onClick={this.props.closeMenu}
                >
                  <div className="flex items-center">
                    <div className="mr-2">{data.name}</div>
                    {data.name === "Tune In" &&
                      <div className="live-container px-2 py-1 leading-none rounded-lg text-white font-black text-sm">LIVE</div>}
                  </div>
                </NavLink>
              );
            })}
          </div>
          <div className="sidebar-footer">

            <div className="social-links flex">
              <a
                href="https://www.twitter.com/mirchiuae"
                target="_blank"
                rel="noopener noreferrer"
                className="relative"
              >
                <img src={require("static/images/twitter.png")} alt="twitter" />
              </a>
              <a
                href="https://www.instagram.com/mirchi_uae/"
                target="_blank"
                rel="noopener noreferrer"
                className="relative"
              >
                <img src={require("../../static/images/instagram-white.svg")} alt="instagram" />
              </a>
              <a
                href="https://www.facebook.com/mirchiuae"
                target="_blank"
                rel="noopener noreferrer"
                className="relative"
              >
                <img src={require("static/images/fb.png")} alt="facebook" />
              </a>
              <a
                href="https://vm.tiktok.com/ZSeJGqFSQ/"
                target="_blank"
                rel="noopener noreferrer"
                className="relative"
              >
                <img
                  src={Tiktok}
                  alt="tiktok"
                  className="h-8 w-8 mr-5"
                />
              </a>
            </div>
            <div className="policy-links text-white text-xs flex">
              <div>Owned by Dolphin Recording Studio LLC</div>
              {/* <div className="mx-1">•</div> */}
              {/* <div>Terms &amp; Conditions</div> */}
            </div>
            <div className="copyright-info">
              <span>
                Copyright 2018 © Entertainment Network (India) Limited. All
                Rights Reserved
              </span>
            </div>
            <div className="enquiry-text my-2 text-xs text-white">For advertising/enquiries, contact us:</div>
            <div className="phone-mail  flex ">
              <a className={"relative block mr-4"} href={`tel:${this.props.activeContactUs && this.props.activeContactUs[0] ? this.props.activeContactUs[0].phone : ''}`}>
                <img src={Phone} alt="phone-icon" />
              </a>
              <a className={"relative block"} href={`mailto:${this.props.activeContactUs && this.props.activeContactUs[0] ? this.props.activeContactUs[0].email : ''}`}>
                <img src={Mail} alt="mail-icon" />
              </a>
            </div>
          </div>


        </div>
      </div>
    );
  }
}

export default connect(
  ({ contactUs }) => ({ ...contactUs }),
  dispatch => bindActionCreators({ ...contactUsActions }, dispatch)
)(headerMenu);
