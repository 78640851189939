const LS_KEY = {
  auth_token: "",
  mirchiTop20: "mirchiTop20",
  mirchiMurga: "mirchiMurga",
  movieReviews: "movieReviews",
  showInstallMessage: "showInstallMessage"
};

const set = {
  authToken: data => {
    localStorage.setItem(
      LS_KEY.auth_token,
      JSON.stringify({
        auth_token: data
      })
    );
  },
  mirchiTop20: data => {
    localStorage.setItem(
      LS_KEY.mirchiTop20,
      JSON.stringify({
        mirchiTop20: data
      })
    );
  },
  mirchiMurga: data => {
    
    localStorage.setItem(
      LS_KEY.mirchiMurga,
      JSON.stringify({
        mirchiMurga: data
      })
    );
  },
  movieReviews: data => {
    localStorage.setItem(
      LS_KEY.movieReviews,
      JSON.stringify({
        movieReviews: data
      })
    );
  },
  showInstallMessage: data => {
    localStorage.setItem(
      LS_KEY.showInstallMessage,
      JSON.stringify({
        showInstallMessage: data
      })
    );
  }
};

const fetch = {
  authToken: () => {
    const data = localStorage.getItem(LS_KEY.auth_token);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded.auth_token;
      } catch (err) {
        console.log(err);
      }
    }
  },
  mirchiTop20: () => {
    const data = localStorage.getItem(LS_KEY.mirchiTop20);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded.mirchiTop20;
      } catch (err) {
        console.log(err);
      }
    }
  },
  mirchiMurga: () => {
    const data = localStorage.getItem(LS_KEY.mirchiMurga);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded.mirchiMurga;
      } catch (err) {
        console.log(err);
      }
    }
  },
  movieReviews: () => {
    const data = localStorage.getItem(LS_KEY.movieReviews);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded.movieReviews;
      } catch (err) {
        console.log(err);
      }
    }
  },
  showInstallMessage: () => {
    const data = localStorage.getItem(LS_KEY.showInstallMessage);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded.showInstallMessage;
      } catch (err) {
        console.log(err);
      }
    }
  }
};

const destroy = {
  authToken: () => {
    localStorage.removeItem(LS_KEY.auth_token);
  },
  mirchiTop20: () => {
    localStorage.removeItem(LS_KEY.mirchiTop20);
  }
};

export const storage = {
  set,
  fetch,
  destroy
};
