const setCityStationsList = data => ({
  type: "SET_CITYSTATIONS_LIST",
  data
});

const setRjDataList = data => ({
  type: "SET_RJDATA",
  data
});

const setActiveCityStations = data => ({
  type: "SET_ACTIVE_CITYSTATIONS",
  data
});

export const mutation = {
  setCityStationsList,
  setActiveCityStations,
  setRjDataList
};
