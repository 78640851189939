import { initialState } from "./state";

export const ui = (state = initialState, action) => {
  switch (action.type) {
    case "SET_UI_LIST": {
      return Object.assign({}, state, {
        uiList: action.data
      });
    }
    case "SET_ACTIVE_UI": {
      return Object.assign({}, state, {
        activeUi: action.data
      });
    }
    case "IS_LOADING": {
      return Object.assign({}, state, {
        isLoading: action.data
      });
    }
    case "SHOW_ABOUT_US_MODAL": {
      return Object.assign({}, state, {
        showAboutUsModal: action.data
      });
    }
    case "SHOW_INQUIRY_MODAL": {
      return Object.assign({}, state, {
        showInquiryModal: action.data
      });
    }
    case "SHOW_COUNTDOWN_MODAL": {
      return Object.assign({}, state, {
        showCountDownModal: action.data
      });
    }
    case "SHOW_FAQ_MODAL": {
      return Object.assign({}, state, {
        showFaqModal: action.data
      });
    }
    case "Show_Player": {
      return Object.assign({}, state, {
        showGlobalPlayer: action.data
      });
    }
    case "PAUSE_AUDIO_PLAYER": {
      return Object.assign({}, state, {
        audioplay: action.data
      });
    }
    case "PAUSE_OTHER_VIDEOS": {
      return Object.assign({}, state, {
        playedVideoData: action.data
      });
    }
    case "SHOW_VIDEO_MODAL": {
      return Object.assign({}, state, {
        showVideoModal: action.data.value,
        videoData: action.data.data
      });
    }
    case "SET_WINDOW_WIDTH_STATE": {
      return Object.assign({}, state, {
        widthState: action.data
      });
    }
    case "SET_COUNTRY_QA": {
      return Object.assign({}, state, {
        isQA: action.data
      });
    }
    case "SET_AUDIO_DATA": {
      return Object.assign({}, state, {
        audioplay: true,
        audioLink: action.data.audioLink,
        audioTitle:  action.data.audioTitle,
        audioType: action.data.audioType,
        showGlobalPlayer: true,
        audioImg: action.data.audioImg,
        audioDescription: action.data.audioDescription
      });
    }
    case "CLEAR_AUDIO_DATA": {
      return Object.assign({}, state, {
        audioplay: false,
        audioLink: '',
        audioTitle:  '',
        audioType: '',
        showGlobalPlayer: false,
        audioImg: '',
        audioDescription: ''
      });
    }
    default:
      return state;
  }
};
