module.exports = [
  {
    heading: "MIRCHI TOP 20",
    url: "/mirchi-top20",
    samePage : true
  },
  {
    heading: "PUNJABI TOP 20",
    url: "https://www.radiomirchi.com/more/punjabi-top-10/",
  },
  {
    heading: "TAMIL TOP 20",
    url: "https://www.radiomirchi.com/more/tamil-top-20/",
  },
  {
    heading: "TELUGU TOP 20",
    url: "https://www.radiomirchi.com/more/telugu-top-20/",
  },
  {
    heading: "MALAYALAM TOP 20",
    url: "https://www.radiomirchi.com/more/malayalam-top-10/",
  },
  {
    heading: "MARATHI TOP 20",
    url: "https://www.radiomirchi.com/more/marathi-top-20/",
  },
  {
    heading: "KANNADA TOP 20",
    url: "https://www.radiomirchi.com/more/kannada-top-20/",
  },
  {
    heading: "BANGLA TOP 20",
    url: "https://www.radiomirchi.com/more/bangla-top-10/",
  },
  {
    heading: "MIRCHI TOP 20 - BY CELEBS",
    subItems : [
        {
            heading : "SHALMALI KHOLGADE",
            url : "https://www.radiomirchi.com/mirchi-top-20/shalmali_kholgade"
        },
        {
            heading : "SHIRLEY SETIA",
            url : "https://www.radiomirchi.com/mirchi-top-20/shirley_setia"
        },
        {
            heading : "SHILPA RAO",
            url : "https://www.radiomirchi.com/mirchi-top-20/shilpa_rao"
        },
        {
            heading : "TULSI KUMAR",
            url : "https://www.radiomirchi.com/mirchi-top-20/tulsi_kumar"
        },
        {
            heading : "HARSHDEEP KAUR",
            url : "https://www.radiomirchi.com/mirchi-top-20/harshdeep_kaur"
        },
        {
            heading : "KAUSAR MUNIR",
            url : "https://www.radiomirchi.com/mirchi-top-20/kausar_munir"
        },
        {
            heading : "SWANAND KIRKIRE",
            url : "https://www.radiomirchi.com/mirchi-top-20/swanand_kirkire"
        },
        {
            heading : "AMITABH BHATTACHARYA",
            url : "https://www.radiomirchi.com/mirchi-top-20/amitabh_bhattacharya"
        },
        {
            heading : "IRSHAD KAMIL",
            url : "https://www.radiomirchi.com/mirchi-top-20/irshad_kamil"
        },
        {
            heading : "AMAAL MALIK",
            url : "https://www.radiomirchi.com/mirchi-top-20/amaal_mallik"
        },
        {
            heading : "SALEEM MERCHANT",
            url : "https://www.radiomirchi.com/mirchi-top-20/salim_merchant"
        },
        {
            heading : "MITHOON",
            url : "https://www.radiomirchi.com/mirchi-top-20/mithoon"
        },
        {
            heading : "SHANKAR MAHADEVAN",
            url : "https://www.radiomirchi.com/mirchi-top-20/shankar-mahadevan"
        },
        {
            heading : "AMIT TRIVEDI",
            url : "https://www.radiomirchi.com/mirchi-top-20/amit-trivedi"
        },
        {
            heading : "AR RAHMAN",
            url : "https://www.radiomirchi.com/mirchi-top-20/ar-rahman"
        },
        {
            heading : "ARMAAN MALIK",
            url : "https://www.radiomirchi.com/mirchi-top-20/armaan-malik"
        },
        {
            heading : "ARJUN KANUNGO",
            url : "https://www.radiomirchi.com/mirchi-top-20/arjun-kanungo"
        },
    ]
  },
  {
    heading: "MIRCHI TOP 20 - YEARLY",
    subItems : [
        {
            heading : "2019",
            url : "https://www.radiomirchi.com/mirchi-top20/2019/1-20/13"
        },
        {
            heading : "2018",
            url : "https://www.radiomirchi.com/mirchi-top100/2018/1-20/14"
        },
        {
            heading : "2017",
            url : "https://www.radiomirchi.com/mirchi-top100/2017/1-20/12"
        },
        {
            heading : "2016",
            url : "https://www.radiomirchi.com/mirchi-top100/2016/1-20/8"
        },
        {
            heading : "2015",
            url : "https://www.radiomirchi.com/mirchi-top100/2015/20-1/4"
        },
        {
            heading : "2014",
            url : "https://www.radiomirchi.com/mirchitop20/top100.php"
        },
        {
            heading : "2013",
            url : "https://www.radiomirchi.com/mirchi-top100/2013/1-20/15"
        },
    ]
  },
  {
    heading: "JUBILEE SONGS",
    url: "https://www.radiomirchi.com/more/golden-jubilee/",
  },
];
