import { CityStations } from "../../services/cityStations";
import { mutation } from "./mutations";

const handleError = err => {
  console.log("Error in cityStations action : ", err);
};

// Actions
export const getCityStationsListRequest = () => async dispatch => {
  try {
    let result = await CityStations.getCityStationsList();
    dispatch(mutation.setCityStationsList(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getCityStationsById = (id) => async dispatch => {
  try {
    let result = await CityStations.getCityStationsById(id);
    dispatch(mutation.setActiveCityStations(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getCityStationsBySlug = (slug) => async dispatch => {
  try {
    let result = await CityStations.getCityStationsBySlug(slug);
    dispatch(mutation.setActiveCityStations(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getRjDataList = (payload) => async dispatch => {
  try {
    let result = await CityStations.getRjDataList(payload);
    dispatch(mutation.setRjDataList(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};
