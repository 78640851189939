import React, { Component } from 'react';
import './AudioCard.scss'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as uiActions from "store/ui/actions";
class AudioCard extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  onPlay = async () => {
    const obj = {
      audioLink: this.props.link,
      audioTitle: this.props.title,
      audioType: this.props.type,
      audioImg: this.props.image,
      audioDescription: this.props.description
    };
      await this.props.setAudioPLayerData(obj)
  };
  render() {
    return <div className={`lg:h-full lg:items-center component-audio-card cursor-pointer flex bg-white rounded-lg
     mx-5 lg:mx-2 mb-3 ${this.props.noleftmargin ? ' no-margin-left ' : ''}
     ${this.props.rjDetail? 'rj-detail-page': ''}
     ${this.props.showBorder? 'audio-card-border': ''}
   
     py-3 px-3 ${this.props.type}`} onClick={() => this.onPlay()}>
      <div className="w-10 flex self-center h-auto min-width mr-2 play-img lg:w-8">
        <img src={require('../../static/images/play-button.svg')}
          alt="play-btn" onClick={() => this.onPlay()} />
      </div>
      <div className="flex flex-col justify-center w-64 truncate leading-tight">
        <div className={`font-medium lowercase capitalize whitespace-pre-wrap w-full  tracking-normal
        ${this.props.homeMurga || this.props.rjDetail ? 'text-sm' : 'text-base'}`}>
          {this.props.title.toLowerCase()}
        </div>
        {/* {this.props.date ?
          <div className="font-medium text-sm italic">{this.props.date}</div> : ''} */}
      </div>
    </div>;
  }
}
export default connect(
  ({ ui }) => ({ ...ui }),
  dispatch => bindActionCreators({ ...uiActions }, dispatch)
)(AudioCard);
