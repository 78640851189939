import {requestVideoData, Videos} from "../../services/videos";
import { mutation } from "./mutations";

const handleError = err => {
  console.log("Error in videos action : ", err);
};

// Actions
export const getVideosListRequest = () => async dispatch => {
  try {
    let result = await Videos.requestVideoData();
    dispatch(mutation.setVideosList(result));
  } catch (err) {
    handleError(err);
  }
};
