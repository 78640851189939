import axios from "axios";

export const requestMirchiTop20Data = async () => {
    try {
      return await axios.get('https://www.radiomirchi.com/API/top20');
    } catch (err) {
      return err;
    }
  
};

export const MirchiTop20 = {
  requestMirchiTop20Data
};
