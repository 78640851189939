import { Api } from "./config/request";

export const getRjList = () => {
  return Api.getRequest("rj/");
};


export const getRjDetail = (id) => {
  return Api.getRequest(`rj/${id}`);
};

export const getRjDetailBySlug = (slug) => {
  return Api.getRequest(`rj/slug/${slug}`);
};


export const Rj = { 
    getRjList ,
    getRjDetail,
    getRjDetailBySlug
};
