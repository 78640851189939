import { initialState } from "./state";

export const shows = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SHOWS_LIST": {
      return Object.assign({}, state, {
        showsList: action.data
      });
    }
    case "SET_ACTIVE_SHOWS": {
      return Object.assign({}, state, {
        activeShows: action.data
      });
    }
    default:
      return state;
  }
};
