export const initialState = {
    uiList: [],
    isLoading: false,
    showFaqModal: false,
    showAboutUsModal: false,
    widthState: window.innerWidth,
    audioplay: true,
    audioLink: 'https://s3.radio.co/saf384db36/listen',
    audioTitle: 'DUmmy Title',
    audioType: 'radio',
    showGlobalPlayer: false,
    audioImg: '',
    audioDescription: '',
    isQA: false,
    showVideoModal: false,
    videoData: null,
    showInquiryModal: false,
    showCountDownModal: false,
    playedVideoData: null
  };
