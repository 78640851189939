const setNewsList = data => ({
    type: "SET_NEWS_LIST",
    data
  });

const setActiveNews = data => ({
type: "SET_ACTIVE_NEWS",
data
});
  
  
export const mutation = {
setNewsList,
setActiveNews
};
