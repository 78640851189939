const setRjList = data => ({
    type: "SET_RJ_LIST",
    data
  });

  const setActiveRj = data => ({
    type: "SET_ACTIVE_RJ",
    data
  });
  
  
  export const mutation = {
    setRjList,
    setActiveRj
  };
  