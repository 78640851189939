import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { user } from "./user/reducer";
import { hdStations } from "./hdStations/reducer";
import { cityStations } from "./cityStations/reducer";
import { faq } from "./faq/reducer";
import {ui} from "./ui/reducer";
import {events} from "./events/reducer";
import {contactUs} from "./contactUs/reducer";
import {rj} from "./rj/reducer";
import {shows} from "./shows/reducer";
import {mirchiTop20} from "./mirchiTop20/reducer";
import {mirchiMurga} from "./mirchiMurga/reducer";
import {movieReviews} from "./movieReviews/reducer";
import {onlineStations} from "./onlineStations/reducer";
import {videos} from "./videos/reducer";
import {sponsors} from "./sponsors/reducer";
import {pictures} from './pictures/reducer';
import {gallery} from './gallery/reducer';
import {news} from './news/reducer'
import {podcast} from './podcast/reducer'
import { WP_Reducer } from './wp_store/reducer';

export const rootReducer = {
  user,
  faq,
  hdStations,
  cityStations,
  ui,
  events,
  contactUs,
  rj,
  shows,
  mirchiTop20,
  mirchiMurga,
  movieReviews,
  videos,
  sponsors,
  onlineStations,
  pictures,
  gallery,
  news,
  podcast,
  WP_Reducer
};

const devTool = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const store =
  process.env.NODE_ENV === "development"
    ? createStore(
    combineReducers(rootReducer),
    compose(
      applyMiddleware(thunkMiddleware),
      // devTool
    )
    )
    : createStore(
    combineReducers(rootReducer),
    compose(applyMiddleware(thunkMiddleware))
    );

export default store;
