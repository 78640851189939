const setUiList = data => ({
    type: "SET_UI_LIST",
    data
  });

  const isLoading = data => ({
    type: "IS_LOADING",
    data
  });

  const showGlobalAudioPlayer = data => ({
    type: "Show_Player",
    data
  });

  const PauseAudioPlayer = data => ({
    type: "PAUSE_AUDIO_PLAYER",
    data
  });
  const pauseOtherVideos = data => ({
    type: "PAUSE_OTHER_VIDEOS",
    data
  });
  
  const openVideoPlayerModal = data => ({
    type: "SHOW_VIDEO_MODAL",
    data
  });

  const showAboutUsModal = data => ({
    type: "SHOW_ABOUT_US_MODAL",
    data
  });
  
  const showInquiryModal = data => ({
    type: "SHOW_INQUIRY_MODAL",
    data
  });

  const showCountDownModal = data => ({
    type: "SHOW_COUNTDOWN_MODAL",
    data
  });

  const showFaqModal = data => ({
    type: "SHOW_FAQ_MODAL",
    data
  });

  const setWindowWidthState = data => ({
    type: "SET_WINDOW_WIDTH_STATE",
    data
  });

  const setCountryQA = data => ({
    type: "SET_COUNTRY_QA",
    data
  });

  const setAudioData = data => ({
    type: "SET_AUDIO_DATA",
    data
  });

  const clearAudioData = data => ({
    type: "CLEAR_AUDIO_DATA",
    data
  });

  export const mutation = {
    setUiList,
    isLoading,
    showAboutUsModal,
    showFaqModal,
    setWindowWidthState,
    setAudioData,
    showGlobalAudioPlayer,
    clearAudioData,
    setCountryQA,
    openVideoPlayerModal,
    showInquiryModal,
    showCountDownModal,
    PauseAudioPlayer,
    pauseOtherVideos
  };
