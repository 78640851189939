import "../news.scss";
import React from "react";
import * as NewsDetailsAction from "../../../store/news/actions"
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { validS3Url } from "../../../services/config/request";

class NewsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newsDoc: {},
      newsId: this.props.match.params.news_id,
      width: window.innerWidth
    };
  }
  async componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    await this.props.getNewsDetailRequest(this.state.newsId)
    .then((res) => {
      this.setState({ newsDoc: res});
      console.log(`test - ${this.state.newsDoc}`);
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }
  
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  render() {
      return(
    <div className="wrapper" key>
        <div className="top-content">
          {/* <h2 className="first-title">this.state.contestDoc.titleCadillac presents Mirchi Terminal</h2> */}
          <h2 className="first-title">{this.state.newsDoc.title}</h2>
          <br />
        </div>


        <div className="content" style={{width:"100%"}}>
          <div
            className="sub-content"
            dangerouslySetInnerHTML={{
              __html: this.state.newsDoc.description,
            }}
          ></div>

          <div>
            <img
              src={validS3Url(this.state.newsDoc.image)}
              alt="Unable to load image"
              className="bingo-banner"
              style={{ width: "300px", height: "200px" }}
            />
          </div>
        </div>
      </div>
    );
  }
}

// export default ContestPage;

export default connect(
  ({ newsPage }) => ({ ...newsPage}),
  dispatch => bindActionCreators({ ...NewsDetailsAction}, dispatch)
)(NewsPage);