module.exports = [
  {
    name: "Home",
    link: "/",
    headerName: "Home",
  },
  // {
  //   name: "Tune In",
  //   headerName: "Tune In",
  //   link: "/station"
  // },
  {
    name: "Meet The RJs",
    headerName: "Meet The RJs",
    link: "/rj",
  },
  // {
  //   name: "Mirchi Top 20 ",
  //   headerName: "Mirchi Top 20 ",
  //   link: "/mirchi-top20",
  // },
  {
    name: "Videos",
    headerName: "Videos",
    link: "/videos",
  },
  {
    name: "Pictures",
    headerName: "Pictures",
    link: "/pictures",
  },
  // {
  //   name: "Mirchi Murga",
  //   headerName: "Mirchi Murga",
  //   link: "/mirchi-murga",
  // },
  {
    name: "Contests",
    headerName: "Contest",
    link: "/contest",
  },
  // {
  //   name: "New on Mirchi",
  //   headerName: "New on Mirchi",
  //   link: "/new-on-mirchi",
  // },
  
  // {
  //   name: "Podcast",
  //   headerName: "Podcast",
  //   link: "/podcast"
  // },
  // {
  //   name: "News",
  //   headerName: "News",
  //   link: "/news",
  // },
  {
    name: "About Us",
    headerName: "About Us",
    link: "/about-us",
  },
  /*
  TODO: uncomment to get mirchi movie reviews in navbar
  {
    name: "Mirchi Movie Reviews",
    headerName: "Movie Reviews",
    link: "/reviews"
  },*/
  //{
  //  name: "About Us",
  //  headerName: "About Us",
  // link: "/about-us"
  //},
  // {
  //   name: "FAQs - HD Radio",
  //   headerName: "FAQs",
  //   link: "/tune-hd"
  // },
  {
    name: "Contact Us",
    headerName: "Contact Us",
    link: "/contact-us"
  },
];
