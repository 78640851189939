import React, { Component } from "react";
import "./reviewCard.scss";
import ImageCard from "../imageCard";
import FullMirchi from "static/images/full-mirchi.svg";
import HalfMirchi from "static/images/half-mirchi.svg";
import EmptyMirchi from "static/images/empty-mirchi.svg";
import moment from "moment";
import PlayButton from "static/images/play-button.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as uiActions from "store/ui/actions";

class reviewCard extends Component {
  getReviews = review => {
    let dom_content = [];
    if (Number.isInteger(review)) {
      for (let i = 0; i < review; i++) {
        dom_content.push(
          <img
            src={FullMirchi}
            className="full-mirchi-img mirchi  img-review lg:w-10"
            alt="fullMirchi"
            key={`full-${i}`}
          />
        );
      }
      for (let i = 0; i < 5 - review; i++) {
        dom_content.push(
          <img
            src={EmptyMirchi}
            className="full-mirchi-img mirchi img-review lg:w-10"
            key={`empty-${i}`}
            alt="fullMirchi"
          />
        );
      }
    } else {
      for (let i = 0; i < Math.floor(review); i++) {
        dom_content.push(
          <img
            src={FullMirchi}
            className="full-mirchi-img mirchi img-review lg:w-10"
            alt="fullMirchi"
            key={`full-${i}`}
          />
        );
      }
      dom_content.push(
        <img
          src={HalfMirchi}
          className="full-mirchi-img mirchi img-review lg:w-10"
          alt="fullMirchi"
          key={0}
        />
      );
      for (let i = 0; i < 4 - Math.floor(review); i++) {
        dom_content.push(
          <img
            src={EmptyMirchi}
            className="full-mirchi-img  mirchi img-review lg:w-10"
            key={`empty-${i}`}
            alt="emptyMirchi"
          />
        );
      }
    }
    return <div className="flex mirchi-img-container mb-2">{dom_content}</div>;
  };
  formatDate = date => {
    return moment(date).format("MMMM DD, YYYY");
  };

  onPlay = async () => {
    const obj = {
      audioLink: this.props.link,
      audioTitle: this.props.name,
      audioType: this.props.type,
      audioImg: this.props.src,
      audioDescription: 'Movie review by RJ Abhishek'
    };
    await this.props.setAudioPLayerData(obj);
  };
  render() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    return (
      <div
        className={`component-review-card cursor-pointer ${this.props.listing ? 'listing-screen': ''} relative component-card inline-block font-medium mr-3 mb-5 ${this.props.movieReviewMobile && 'reviews-mobile'}`}>
        <div className={`img-container relative ${(msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) && this.props.reviewHomeCard && 'ie-img-container'} `} onClick={() => this.onPlay()}>
          <div className="overlay rounded-lg absolute h-full w-full top-0 left-0" />
          <img
            src={PlayButton}
            className="absolute left-0 right-0 mx-auto play-icon h-10 w-10"
            alt="play"
          />
          <ImageCard
            src={this.props.src}
            alt="rj-card"
            rjCard={this.props.reviewHomeCard ? true : false}
            primary={true}
            className="w-full"
            width={this.props.width}
            height={this.props.height}
            type={"review"}
          />
        </div>
        <div className="movie-name text-left text-base lg:text-sm leading-tight mt-2">
          {this.props.name}
        </div>
        <div className="mirchi-container mt-1">
          {this.getReviews(this.props.review)}
        </div>
        <div className="releaseDate text-left text-xs leading-tight text-gunMetal ">
          Released {this.formatDate(this.props.releaseDate)}
        </div>
      </div>
    );
  }
}

export default connect(
  ({ ui }) => ({ ...ui }),
  dispatch => bindActionCreators({ ...uiActions }, dispatch)
)(reviewCard);
