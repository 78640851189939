import { initialState } from "./state";

export const movieReviews = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MOVIEREVIEWS_LIST": {
      return Object.assign({}, state, {
        movieReviewsList: action.data
      });
    }
    default:
      return state;
  }
};
