import axios from "axios";
import "../contest.scss";
import React from "react";
import { API_URL } from "../../../services/config/apiUrl";
import RedTagMobo from "../../../static/images/RedTag/REDTAG-HOME-Mobo1..png";
import RedTagSponsor from "../../../static/images/RedTag/REDTAG-Sponsor.jpg";

import * as ContestDetailsAction from "../../../store/contest/action";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import contest from "../contest";
import { validS3Url } from "../../../services/config/request";

class ContestPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contestDoc: {},
      contestId: this.props.match.params.contest_id,
      width: window.innerWidth,
    };
    axios.get(API_URL + "/contest/" + this.state.contestId).then((res) => {
      this.setState({ contestDoc: res.data.result });
      console.log(`test - ${this.state.contestDoc}`);
    });
  }
  async componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    let id = this.props.match.params.slug;
    if (!this.props.contestList || this.props.contestList.length == 0) {
      this.props.getContestListRequest().then((res) => {
        var ac = res;
        var fac = ac.find((o) => o.slug == id);
        this.props.getContestDetailRequest(fac.id).then((res) => {
          console.log(this.props);
          this.setState({ contestDoc: res });
          console.log(this.state.contestDoc);
        });
      });
    } else {
      var ac = this.props.contestList;
      var fac = ac.find((o) => o.slug == id);
      this.props.getContestDetailRequest(fac.id).then((res) => {
        console.log(this.props);
        this.setState({ contestDoc: res });
        console.log(`test - ${this.state.contestDoc}`);
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  render() {
    return (
      <div className="wrapper" key>
        <div className="top-content">
          <h2 className="first-title">{this.state.contestDoc.title}</h2>
          <br />

          <img
            src={`${
              this.state.width > 836
                ? validS3Url(this.state.contestDoc.banner)
                : validS3Url(this.state.contestDoc.bannerMobo)
            }`}
            alt="Unable to load img"
            className="contest-banner"
          />

          {/* <img
            // src= {`${(this.props.widthState >= 836) ? MirchiTerminal : MirchiTerminalMobo}`}
           src={MirchiTerminalMobo}
            alt="Unable to load image"
            className="bingo-banner hidden-tablet"
          /> */}
        </div>
        <br />
        <br />
        <br />
        <div className="content">
          {/* <div
            className="sub-content"
            dangerouslySetInnerHTML={{
              __html: this.state.contestDoc.description,
            }}
            style={{ textAlign: "justify" }}
          ></div> */}

          <br />
          <br />
          <br />
          <h2 className="second-title">{this.state.contestDoc.subTitle1}</h2>
          <br />
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.contestDoc.subDescription1,
            }}
          ></div>
          <br />
          <br />

          <h2 className="third-title">{this.state.contestDoc.subTitle2}</h2>
          <br />
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.contestDoc.subDescription2,
            }}
          ></div>

          {/* GoogleForm */}
          {this.state.contestDoc.isSignUpAvailable ? (
            <p style={{ fontWeight: "bold" }}>
              Click{" "}
              <a
                href={this.state.contestDoc.signUpUrl}
                target="_blank"
                style={{ color: "red" }}
              >
                Here
              </a>{" "}
              to register for {this.state.contestDoc.name} !
            </p>
          ) : null}
          <br></br>
          {this.state.contestDoc.terms ? (
            <div>
              <h2 className="fourth-title">Terms and conditions</h2>
              <br></br>
              <div
                style={{ lineHeight: "1.5" }}
                dangerouslySetInnerHTML={{
                  __html: this.state.contestDoc.terms,
                }}
              ></div>
            </div>
          ) : null}

          <br></br>
          {this.state.contestDoc.sponsorLogo ? (
            <div>
              <h2 className="fifth-title">Sponsors</h2>
              <br></br>
              <div className="sponsor">
                <img
                  className="sponsorLogo"
                  src={this.state.contestDoc.sponsorLogo}
                  alt="Sponsor Logo"
                />
              </div>
            </div>
          ) : null}

          {/* <div className="mainSponsors" >
            <img src={Cadillac} alt="Cadillac logo" />
            <img src={"https://www.india.com/wp-content/uploads/2021/06/zee5-logo14289.png"} alt="Zee5 logo" style={{ marginTop: "5%" }} />
          </div>

          <div className="otherSponsors" >
            <img src={KSL} alt="Ksl logo" />
            <img src={CityExchange} alt="CityExchange logo" />
            <img src={Splash} alt="Splash logo" />
            <img src={Gangour} alt="Gangour" />
          </div>
          <div className="otherSponsors">
            <img src={BAWREE} alt="BAWREE logo" />
            <img src={KoraDubai} alt="KoraDubai logo" />
            <img src={Bafleh} alt="Bafleh logo" />
            <img src={AFC} alt="AFC logo" />
          </div>
          <br></br> */}
        </div>
      </div>
    );
  }
}

// export default ContestPage;

export default connect(
  ({ contestPage }) => ({ ...contestPage }),
  (dispatch) => bindActionCreators({ ...ContestDetailsAction }, dispatch)
)(ContestPage);
