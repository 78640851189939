import { Rj } from "../../services/rj";
import { mutation } from "./mutations";

const handleError = err => {
  console.log("Error in rj action : ", err);
};

// Actions
export const getRjListRequest = () => async dispatch => {
  try {
    let result = await Rj.getRjList();
    dispatch(mutation.setRjList(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};
export const getRjDetailRequest = (id) => async dispatch => {
  try {
    let result = await Rj.getRjDetail(id);
    dispatch(mutation.setActiveRj(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getRjDetailRequestBySlug = (slug) => async dispatch => {
  try {
    let result = await Rj.getRjDetailBySlug(slug);
    dispatch(mutation.setActiveRj(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};
