import React, { Component } from "react";
import "./globalPlayer.scss";
import AudioPlayer from "react-h5-audio-player";
import * as uiActions from "store/ui/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PlayIcon from "static/images/play-button.svg";
import PauseIcon from "static/images/pause-circle.svg";
import LoadingGif from "static/images/player-loader.gif";
import { validS3Url } from "../../services/config/request";

class globalPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canPlay: false,
      isPlaying: false
    };
  }

  handleClick = () => {
    this.props.showGlobalAudioPlayer(false);
  };
  createMarkup = data => {
    return { __html: data };
  };
  handleCloseAudioPlayer = () => {
    this.props.hideAudioPlayerData();
  }
  handlePlay = async () => {
    try {

      if (this.player && this.player.audio.played.length) {
        await this.setState({ canPlay: true, isPlaying: true });
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  async componentWillReceiveProps(nextProps) {
    if (this.props.audioplay !== nextProps.audioplay) {
      if (nextProps.audioplay === false) {
        this.player.audio.pause();
        await this.setState({ canPlay: true, isPlaying: false });
      }
    }
  }

  async componentDidMount() {

    const userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || navigator.userAgent.match('CriOS')) {
      await this.setState({ canPlay: true })
    }
    // const userAgent = window.navigator.userAgent;
    // if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || navigator.userAgent.match('CriOS')) {
    //   // if (navigator.userAgent.indexOf('Safari') === -1)
    //   await this.setState({ canPlay: true })
    // }
    if (userAgent.toLowerCase().indexOf('android') > -1) {
      await this.setState({ canPlay: false })
    }
    else if (userAgent.toLowerCase().indexOf('macintosh') > -1) {
      await this.setState({ canPlay: true })
    }
  }
  handleAbort = async () => {
    await this.setState({ canPlay: false, isPlaying: false });
  };

  playAudio = async () => {
    this.props.pauseAudioPlayer(true);
    this.player.audio.play();
    await this.setState({ canPlay: true, isPlaying: true });
  };

  pauseAudio = async () => {
    this.player.audio.pause();
    await this.setState({ canPlay: true, isPlaying: false });
  };

  render() {
    return (
      <div
        className={`fixed h-auto lg:h-20 bg-white bottom-0 z-50 left-0 w-full py-3 px-3 lg:px-0  component-global-player
        ${this.state.canPlay ? "canPlay" : "loading"} ${this.props.audioType}
       w-full `}
      >
        <div
          className={`h-full relative inner-global-player mx-auto ${this.props.audioType} w-full flex justify-center px-3 `}
        >
          <div className="inner-container flex items-start flex-col justify-between h-full w-full lg:w-4/5 ">
            <div
              className="w-full flex items-center mb-2 lg:mb-0"
              style={{ flex: "0 0 auto" }}
            >
              {this.state.canPlay ? (
                !this.state.isPlaying ? (
                  <img
                    src={PlayIcon}
                    alt="play-icon"
                    onClick={() => this.playAudio()}
                    className={`w-6 h-6 lg:w-8 lg:h-8  mr-2  img-fluid object-cover rounded-sm `}
                  />
                ) : (
                    <img
                      src={PauseIcon}
                      onClick={() => this.pauseAudio()}
                      alt="pause-icon"
                      className={`w-6 h-6 lg:w-8 lg:h-8 mr-2  img-fluid object-cover rounded-sm `}
                    />
                  )
              ) : (
                  <img
                    src={LoadingGif}
                    alt="loader-icon"
                    className={`w-6 h-6 lg:w-8 lg:h-8  mr-2  img-fluid object-cover rounded-sm `}
                  />
                )}
              <div
                className={`audio-title  text-black text-xs flex justify-start items-center truncate w-fills
              ${this.props.audioType}`}
              >
                {/* {this.props.audioImg ? <img
                  src={this.props.audioImg}
                  alt="card-img"
                  className={`w-6 h-6 lg:h-10 lg:w-10 mr-2  img-fluid object-cover rounded-sm `}
                /> : ''} */}
                <div className=" lg:flex lg:flex-col audioTitle ">
                  {this.props.audioTitle ? (
                    <div
                      className={
                        "capitalize mb-1 lg:mb-0 truncate audio-title-span "
                      }
                    >
                      {this.props.audioTitle}
                    </div>
                  ) : (
                      ""
                    )}
                  {this.props.audioDescription &&
                    this.props.audioDescription.trim().length ? (
                      <span
                        className="ml-0 whitespace-pre-wrap"
                        dangerouslySetInnerHTML={this.createMarkup(
                          this.props.audioDescription
                        )}
                      />
                    ) : (
                      ""
                    )}
                </div>
              </div>
            </div>

            {navigator.userAgent.indexOf("chrome") === -1
              ? (<AudioPlayer
                progressUpdateInterval={500}
                autoPlay
                style={{ flex: "1 1 auto" }}
                className={`player audio-player player-component w-full ml-0 pl-0
              ${this.props.audioType}
              ${this.state.canPlay ? "canPlay" : "loading"}`}
                src={validS3Url(this.props.audioLink)}
                onPlay={() => this.handlePlay()}
                onListen={e => {

                  if (!this.state.canPlay) {
                    this.handlePlay();
                  } else {
                    this.setState({ isPlaying: true })
                  }
                }}
                onAbort={() => this.handleAbort()}
                ref={c => (this.player = c)}
              />) : (<audio ref={c => (this.player = c)} autoplay loop id="playAudio">
                <source src={validS3Url(this.props.audioLink)} />
              </audio>)}
          </div>
          <div onClick={this.handleCloseAudioPlayer} className="close-icon-holder absolute cursor-pointer">
            <img alt="close-icon" className="close-icon" src={require("../../static/images/cross.svg")} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ ui }) => ({ ...ui }),
  dispatch => bindActionCreators({ ...uiActions }, dispatch)
)(globalPlayer);
