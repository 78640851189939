import { initialState } from "./state";

export const gallery = (state = initialState, action) => {
  switch (action.type) {
    case "SET_GALLERY_LIST": {
      return Object.assign({}, state, {
        galleryList: action.data
      });
    }
    case "SET_ACTIVE_GALLERY": {
      return Object.assign({}, state, {
        activeGallery: action.data
      });
    }
    case "SET_GALLERYIMAGE_LIST": {
      return Object.assign({}, state, {
        galleryImageList: action.data
      })
    }
    default:
      return state;
  }
};
