// import React from 'react';
import './blogCardFeatured.scss'
import ImageCard from "../imageCard";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as wpActions from "../../store/wp_store/actions";
import * as uiActions from "store/ui/actions";
import { Link } from "react-router-dom";
// import "./WPCard.scss";

class blogCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgUrl: '',
      category:[],
      isLoaded: false
    }
  }
  componentDidMount() {
    // console.log(this.props);
    const { featured_media } = this.props.data;
    const { categories} = this.props.data;

    if (featured_media && featured_media != 0 && categories && categories != 0) {

      const getImageUrl = this.props.getMediaByIdRequest(featured_media);
      const getCategories = this.props.getCategoryByIdRequest(categories[0]);

      Promise.all([getImageUrl]).then(res => {
        // console.log(res);
        {
          this.props.size == "big" ?
            this.setState({
              imgUrl: res[0].media_details.sizes["filmax-thumb-med"].source_url,
              isLoaded: true
            }) :
            this.setState({
              imgUrl: res[0].media_details.sizes["filmax-thumb-med"].source_url,
              isLoaded: true
            })
        }
      })
      Promise.all([getCategories]).then(res => {
        // console.log(res);
        this.setState({
          category: res[0].name,
          isLoaded: true
        });
      })
    }
    else {
      this.setState({
        imgUrl: "",
        category:[],
        isLoaded: true
      });
    }
  }
  render() {
    // console.log(this.props.data);
    const { title, date } = this.props.data;
    const { imgUrl,category, isLoaded } = this.state;

    let dateConv = new Date(date);


    function month_name(dt) {
      var mlist = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      return mlist[dt];
    };
   
    let dateMDY = `On ${month_name(dateConv.getMonth() + 1)} ${dateConv.getDate()}, ${dateConv.getFullYear()}`;

    if (isLoaded) {
      return (
        <div className="singleTile">
          <img src={imgUrl} alt="Featured Blog" />
          <div className="overlay"></div>
          <div className="blog-content">
            <h1 
            className="category3"
            >{category}</h1>
           
            <div 
            className={this.props.size=="big"?"title3-big":"title3"}
              dangerouslySetInnerHTML={{
                __html: title.rendered,
              }}
            />

            <div className="date3" 
              dangerouslySetInnerHTML={{
                __html: dateMDY,
              }}></div>
          </div>
        </div>
      );
    }
    return null;
  }
}
export default connect(
  ({ wp, ui }) => ({ ...wp, ...ui }),
  dispatch => bindActionCreators({ ...wpActions, ...uiActions }, dispatch)
)(blogCard);