import { Api } from "./config/request";

export const requestMovieReviewsData = async () => {
    try {
    return Api.getRequest("movieReviews");
    } catch (err) {
      return err;
    }
  
};

export const MovieReviews = {
    requestMovieReviewsData
};
