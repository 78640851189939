import { initialState } from "./state";

export const mirchiMurga = (state = initialState, action) => {
  
  switch (action.type) {
    case "SET_MIRCHIMURGA_LIST": {
      return Object.assign({}, state, {
        mirchiMurgaList: action.data
      });
    }
    default:
      return state;

  }
};
