import { WP_Api } from "./config/wp_request";

export const getNewsCatList = () => {
  return WP_Api.getRequest("news_catagories/");
};

export const getNewsList = (params = null) => {
  return WP_Api.getRequest(`news${params != null ? "?"+params : ""}`);
};

export const getMediaById = (id) => {
  return WP_Api.getRequest(`media/${id}`);
}

export const getBlogList = () => {
  return WP_Api.getRequest(`posts`);
}

export const getBlogById = (id) => {
  return WP_Api.getRequest(`posts/${id}`);
}

export const getCategoryById = (id) => {
  return WP_Api.getRequest(`categories/${id}`);
}

export const getAuthorById = (id) => {
  return WP_Api.getRequest(`users/${id}`);
}

export const getRelatedBlogById = (id) => {
  return WP_Api.getRequest(`posts?categories=${id}`);
}

export const WP_Service = { 
  getNewsCatList,
  getNewsList,
  getMediaById,
  getBlogList,
  getBlogById,
  getCategoryById,
  getAuthorById,
  getRelatedBlogById
};
