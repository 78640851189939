import { OnlineStations } from "../../services/onlineStations";
import { mutation } from "./mutations";

const handleError = err => {
  console.log("Error in online stations action : ", err);
};

// Actions
export const getOnlineStationsListRequest = () => async dispatch => {
  try {
    let result = await OnlineStations.getOnlineStationsList();
    dispatch(mutation.setOnlineStationsList(result));
  } catch (err) {
    handleError(err);
  }
};
