const setContactUsList = data => ({
    type: "SET_CONTACT_US_LIST",
    data
  });

  const setActiveContactUs = data => ({
    type: "SET_ACTIVE_CONTACT_US",
    data
  });


  export const mutation = {
    setContactUsList,
    setActiveContactUs
  };
