import { initialState } from "./state";

export const pictures = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PICTURES_LIST": {
      return {
        ...state,
        pictures: action.data
      }
    }
    default:
      return state;
  }
};
