const setHomeList = data => ({
  type: "SET_HOME_LIST",
  data
});

const setHomeSlider = data => ({
  type: "SET_HOME_SLIDER",
  data
})


export const mutation = {
  setHomeList,
  setHomeSlider
};
