import { Api } from "./config/request";

export const getGalleryList = () => {
  return Api.getRequest("gallery/");
};

export const getGalleryImagesList = (id) => {
  return Api.getRequest(`galleryImage/gallery/${id}`);
}


export const Gallery = { 
  getGalleryList,
  getGalleryImagesList
};
