import { MovieReviews } from "../../services/movieReviews";
import { mutation } from "./mutations";
import {storage as LocalStorage} from "../../services/config/storage";
const handleError = err => {
  console.log("Error in movieReviews action : ", err);
};

// Actions
export const getMovieReviewsListRequest = () => async dispatch => {
  try {
    let result = await MovieReviews.requestMovieReviewsData();
    result = result.sort((a,b) => new Date(b.releaseDate).getTime()  - new Date(a.releaseDate).getTime())
    LocalStorage.set.movieReviews(result);
    dispatch(mutation.setMovieReviewsList(result));
  } catch (err) {
    handleError(err);
  }
};