// import React from 'react';
import './blogCard.scss'
import ImageCard from "../imageCard";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as wpActions from "../../store/wp_store/actions";
import * as uiActions from "store/ui/actions";
import { Link } from "react-router-dom";
// import "./WPCard.scss";

class blogCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgUrl: '',
      category: "",
      isLoaded: false
    }
  }
  componentDidMount() {
    // console.log(this.props);
    const { featured_media } = this.props.data;
    const { categories } = this.props.data;
    // console.log(categories[0]);
    if (featured_media && featured_media != 0 && categories && categories != 0) {
      const getImageUrl = this.props.getMediaByIdRequest(featured_media);
      const getCategories = this.props.getCategoryByIdRequest(categories[0]);

      Promise.all([getImageUrl]).then(res => {
        this.setState({
          imgUrl: res[0].media_details.sizes.medium.source_url,
          // isLoaded: true
        });
      })

      Promise.all([getCategories]).then(res => {
        // console.log(res);
        this.setState({
          category: res[0].name,
          isLoaded: true
        });
      })
    }
    else {
      this.setState({
        imgUrl: "",
        category: "",
        isLoaded: true
      });
    }
  }
  render() {
    const { name, excerpt, slug, date } = this.props.data;
    const { imgUrl, isLoaded, category } = this.state;
    let dateConv = new Date(date);


    function month_name(dt) {
      var mlist = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      return mlist[dt];
    };

    let dateMDY = `On ${month_name(dateConv.getMonth() + 1)} ${dateConv.getDate()}, ${dateConv.getFullYear()}`;
    
    if (isLoaded) {
      return (
        <div className={`relative  inline-block ${this.props.home && 'mr-3'}`}
          style={{ width: this.props.cardWidth, height: this.props.cardHeight }}>

          <ImageCard
            primary
            rjimg
            rjCard={this.props.rjHomeCard ? true : false}
            rjShadow={this.props.rjShadow ? true : false}
            src={imgUrl}
            alt="rj-card"
            width={this.props.width}
            height={this.props.height} />
          <div className="card-details absolute cardoverlay">

            <div className="category" style={{ color: "rgba(236,170,76,255)" }}>{category}</div>
            

            <div className="desc"
              dangerouslySetInnerHTML={{
                __html: (this.props.name.length > 25?(this.props.name.substring(0, 60) + "..."):""),
              }}
            ></div>

            <p style={{ fontSize: "7px", color: "white", textTransform: "uppercase", paddingLeft: " 5%" }}
              dangerouslySetInnerHTML={{
                __html: dateMDY,
              }}></p>
          </div>
        </div>
      );
    }
    return null;
  }
}
export default connect(
  ({ wp, ui }) => ({ ...wp, ...ui }),
  dispatch => bindActionCreators({ ...wpActions, ...uiActions }, dispatch)
)(blogCard);