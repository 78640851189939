import { ContactUs } from "../../services/contactUs";
import { mutation } from "./mutations";

const handleError = err => {
  console.log("Error in contactUs action : ", err);
};

// Actions
export const getContactUsListRequest = () => async dispatch => {
  try {
    let result = await ContactUs.getContactUsList();
    dispatch(mutation.setActiveContactUs(result));
    return result;

  } catch (err) {
    handleError(err);
  }
};
export const sendEmail = (data) => async dispatch => {
  try{
    let result = await ContactUs.ContactUsSendEmail(data);
    
    return result;
  }
  catch(err){
    handleError(err);
  }
}
