import './blogCardMini.scss'
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as wpActions from "../../store/wp_store/actions";
import * as uiActions from "store/ui/actions";
import { validS3Url } from '../../services/config/request';


class blogCardMini extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgUrl: '',
      isLoaded: false
    }
  }
  componentDidMount() {
    // console.log(this.props);
    const { featured_media } = this.props.data;
    { console.log(featured_media) }

    if (featured_media && featured_media != 0) {

      const getImageUrl = this.props.getMediaByIdRequest(featured_media);

      Promise.all([getImageUrl]).then(res => {
        this.setState({
          imgUrl: res[0].media_details.sizes["post-thumbnail"].source_url,
          isLoaded: true
        });
      })
    }
    else {
      this.setState({
        imgUrl: "",
        isLoaded: true
      });
    }
  }
  render() {
    const { title, excerpt, slug } = this.props.data;
    const { imgUrl, isLoaded } = this.state;

    if (isLoaded) {
      return (

        <div className="singleBlog1 lg">
          <div className="blogImg1">
            <img src={validS3Url(imgUrl)} alt="related blog" />
          </div>
          <div className="blog-about1">
            <div
              className="title1"
              dangerouslySetInnerHTML={{
                __html: title.rendered,
              }}></div>

          </div>
        </div>

      );
    }
    return null;
  }
}
export default connect(
  ({ wp, ui }) => ({ ...wp, ...ui }),
  dispatch => bindActionCreators({ ...wpActions, ...uiActions }, dispatch)
)(blogCardMini);