import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import ReactDOM from 'react-dom';
import './index.css';
import {Provider} from "react-redux";
import "./static/scss/fonts.scss";
import "./static/scss/common.scss";
import "./static/scss/tailwind.scss";
import store from "../src/store";
import AppRouter from "./router/appRouter";
import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {storage as LocalStorage} from "./services/config/storage";
import {mutation} from "./store/mirchiTop20/mutations";
import {mutation as MirchiMurgaMutation} from "./store/mirchiMurga/mutations";
import {mutation as MovieReviewsMutation} from "./store/movieReviews/mutations";
import HealthCheck from './views/healthcheck/healthcheck';


const loadState = () => {
  try {
    
    const mirchiTop20 = LocalStorage.fetch.mirchiTop20();
    const mirchiMurga = LocalStorage.fetch.mirchiMurga();
    const movieReviews = LocalStorage.fetch.movieReviews();
    if (mirchiTop20) {
      store.dispatch(mutation.setMirchiTop20List(mirchiTop20));
    }
    if (mirchiMurga) {
      store.dispatch(MirchiMurgaMutation.setMirchiMurgaList(mirchiMurga));
    }
    if (movieReviews) {
      store.dispatch(MovieReviewsMutation.setMovieReviewsList(mirchiMurga));
    }
   
   
    return JSON.parse(mirchiTop20);
  } catch (e) {
    return undefined;
  }
};

if(!window.location.pathname === '/healthcheck'){
  loadState();
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      {window.location.pathname === '/healthcheck' ? <HealthCheck/> : <AppRouter/>}
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about services workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
