import { initialState } from "./state";

export const rj = (state = initialState, action) => {
  switch (action.type) {
    case "SET_RJ_LIST": {
      return Object.assign({}, state, {
        rjList: action.data
      });
    }
    case "SET_ACTIVE_RJ": {
      return Object.assign({}, state, {
        activeRj: action.data
      });
    }
    default:
      return state;
  }
};
