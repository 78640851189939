import React, {PureComponent} from "react";
import "./imageCard.scss";
import Shimmer from 'static/images/shimmer.jpg'
import { validS3Url } from "../../services/config/request";

export default class imageCard extends PureComponent {
  componentDidMount() {
    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if(this.props.magicInfo) {
          }
          if (entry.isIntersecting && this.element ) {
            if ((this.element && this.element.src.indexOf('shimmer') !== -1) || this.element.src !== this.props.src ) {
              this.element.src = validS3Url(this.props.src);
              return 1 ;
            }
          } else  {
            
          }
        });
      }
    );
    this.observer.observe(this.element);
  }
  
  render() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    return (
      <>
        {
          this.props.rjShadow || this.props.stationCard ? <div className={`relative ${(msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) && this.props.rjCard && 'ie-image-container'}`} >
              <img
                ref={el => this.element = el}
                alt="RJs Mirchi 102.4 FM Dubai Mirchi Radio Station"
                src={Shimmer}
                style={{width: this.props.width, height: this.props.height}}
                className={` w-full component-img-card ${!this.props.stationDetail && 'image-card'}  w-full img-fluid object-cover
                ${this.props.home && this.props.stationCard && 'home-station-card'}
              ${this.props.primary && "border-10"} ${this.props.rjimg && "rounded-full mb-1"} ${this.props.videoimg && 'rounded'}
              ${this.props.movieCard && 'mb-10 rounded-lg'} ${this.props.rjImgDesktop && 'rjImgDesktop'}
              ${this.props.mirchiTop20 && 'mirchiTop20'} ${this.props.magicInfo && 'magicInfo'}
              ${this.props.active && 'img-desktop img-fluid mb-6 rounded-full'} ${this.props.top20 && 'top20Card'}
              ${this.props.showCard && ' mr-5'} ${this.props.halfImg && 'halfImg'} ${this.props.showCard && this.props.rjimg && 'mr-0'}
              ${this.props.borderImg && 'border border-solid border-white'} 
              ${this.props.stationDetailBanner && 'stationDetailBanner'}
              ${this.props.type} ${(msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) && this.props.rjCard && 'ie-img'}`
           }
              />
              <div className={`absolute w-full h-32 bottom-shadow ${this.props.primary ? 'border-10' : ''}`}/>
            </div> :
            <img
              src={Shimmer}
              alt="card-img"
              ref={el => this.element = el}
              style={{width: this.props.width, height: this.props.height}}
              className={`component-img-card ${this.props.showDetailDesktop && 'mr-10'} ${!this.props.stationDetail && 'image-card'}
               w-full
            img-fluid object-cover ${this.props.primary && "border-10"} ${this.props.rjimg && "rounded-full mr-2 mb-1"} ${this.props.videoimg && 'rounded'}
            ${this.props.movieCard && 'mb-10 rounded-lg'}  ${this.props.rjImgDesktop && 'rjImgDesktop'}
             ${this.props.active && 'img-desktop img-fluid mb-6' +
              ' rounded-full'} ${this.props.magicInfo && 'magicInfo'}
            ${this.props.top20 && 'top20Card'} ${this.props.showCard && 'lg:mb-2 mr-5'}
              ${this.props.mirchiTop20 && 'mirchiTop20'} ${this.props.halfImg && 'halfImg'}
              ${this.props.type}
            ${this.props.borderImg && 'border border-solid border-white'} ${this.props.showCard && this.props.rjimg && 'margin-0-img mr-0'} ${(msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) && this.props.rjCard  && 'ie-img'}`}
            />
        }
      </>
    );
  }
  
}
