import { Api } from "./config/request";

export const requestVideoData = async () => {
  try {
    return Api.getRequest("video/");
  } catch (err) {
    return err;
  }
  
};

export const Videos = {
  requestVideoData
};
