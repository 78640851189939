import { initialState } from "./state";

export const onlineStations = (state = initialState, action) => {
  
  switch (action.type) {
    case "SET_ONLINE_STATIONS_LIST": {
      return Object.assign({}, state, {
        onlineStationsList: action.data
      });
    }
    default:
      return state;

  }
};
