import { Api } from "./config/request";

export const getEventsList = ()  => {
  return Api.getRequest("event/");
};
export const getEventDetailList = (payload)  => {
  return Api.getRequest(`event/${payload}`);
};
export const Events = { 
    getEventsList ,
    getEventDetailList,
};
