import { Api } from "./config/request";

export const getNewsList = () => {
  return Api.getRequest("news/");
};


export const getNewsDetail = (id) => {
  return Api.getRequest(`news/${id}`);
};


export const News = { 
    getNewsList ,
    getNewsDetail,
};
