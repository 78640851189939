module.exports = [
  {
    name: "Aladin",
    rating: 3.5,
    releaseDate: "24/05/2019",
    image: require('static/images/movie-review/mr-aladin.png'),
    link: 'https://apiradiomirchicms.s3.amazonaws.com/1561648481399-MIRCHI%2BBIOSCOPE_ALADIN%2B%26%2BINDIAS%2BMOST%2BWANTED_USA.mp3'
  },
  {
    name: "De De Pyaar De",
    rating: 3,
    releaseDate: "16/05/2019",
    image: require('static/images/movie-review/mr-dedepyaarde.png'),
    link: 'https://apiradiomirchicms.s3.amazonaws.com/1561648410689-MIRCHI%2BBIOSCOPE%2B-%2BDE%2BDE%2BPYAAR%2BDE%2B-%2BRE.mp3'
  },
  {
    name: "Godzilla",
    rating: 5,
    releaseDate: "13/05/2019",
    image: require('static/images/movie-review/mr-godzilla.png'),
    link: 'https://apiradiomirchicms.s3.amazonaws.com/1561648581625-MIRCHI%2BBISOCOPE_GODZILLA_USA.mp3'
  },
  {
    name: "Student Of The Year 2",
    rating: 2.5,
    releaseDate: "10/05/2019",
    image: require('static/images/movie-review/mr-soty2.png'),
    link: 'https://apiradiomirchicms.s3.amazonaws.com/1561648532027-MIRCHI%2BBIOSCOPE_SOTY%2B02_USA.mp3'
  },
  {
    name: "Bharat",
    rating: 4,
    releaseDate: "5/05/2019",
    image: require('static/images/movie-review/mr-bharat.png'),
    link: 'https://apiradiomirchicms.s3.amazonaws.com/1561648299462-MIRCHI%2BBIOSCOPE%2B-%2BBHARAT%2B-%2BUSA.mp3'
  },
  
 
 
  
  
];
