import React from "react";
import "./cardsHeader.scss";
import { Link } from "react-router-dom";

export default function cardsHeader(props) {
  return (
    <div className="cards-header flex lg:items-end items-center justify-between py-4 lg:pr-3">
      <div className="header-section flex items-center">
        {props.live && (
          <div className="relative live-container mr-2 leading-none rounded-lg text-white font-black text-sm">
            LIVE
            <div className="absolute rounded-full dark-red-ring"/>
            <div className="absolute rounded-full light-red-ring"/>
          </div>
        )}
        <div className="header-text">
          {
            props.headtype == 'h1' ? (
              <h1 className={`font-black leading-none ${props.size ? `text-${props.size}xl` : "text-2xl"} tracking-tight pb-2`}>
                {props.heading}
              </h1>

            ) : (
              <h2 className="font-black leading-none text-2xl tracking-tight pb-2">
                {props.heading}
              </h2>
            )
          }
          <div className="text-base  subheading tracking-normal">{props.subheading}</div>
        </div>
      </div>
      {props.showArrows && !props.noViewAll ?
        <Link to={`${props.viewAll}`}>
          <div className="flex items-center">
            <div className="hidden-tablet font-black italic tracking-tight leading-none text-lg mr-3">
              View All
          </div>
            <img className="lg:block hidden" src={require('static/images/view-all-arrow.svg')} alt="arrow-img" />
            <img className="lg:hidden block" src={require('static/images/arrow.svg')} alt="arrow-img" />
          </div>
        </Link> : ''}
    </div>
  );
}
