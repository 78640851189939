import { Gallery } from "../../services/gallery";
import { mutation } from "./mutations";

const handleError = err => {
  console.log("Error in gallery action : ", err);
};

// Actions
export const getGalleryListRequest = () => async dispatch => {
  try {
    let result = await Gallery.getGalleryList();
    dispatch(mutation.setGalleryList(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getGalleryImagesListRequest = (id) => async dispatch => {
  try{
    let result = await Gallery.getGalleryImagesList(id);
    console.log(result)
    dispatch(mutation.setGalleryImageList(result))
    return result
  }
  catch(err){
    handleError(err);
  }
}