const setContestList = data => ({
    type: "SET_CONTEST_LIST",
    data
  });

const setActiveContest = data => ({
type: "SET_ACTIVE_CONTEST",
data
});
  
  
export const mutation = {
setContestList,
setActiveContest
};
