import React, { Component } from "react";
import "./footer.scss";
import MirchiLogo from "static/images/logo.svg";

import UAEMirchiLogo from "static/images/uae-logo.png";
//import UAEMirchiLogo from "static/images/uae-logo-footer.png";

import FacebookColored from "static/images/facebook-colored.svg";
import TwitterColored from "static/images/twitter-colored.svg";
import InstagramColored from "static/images/instagram-colored.svg";
import PlayStoreIcons from "./playstoreicons";
import FooterData from "data/menuData.js";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as uiActions from "store/ui/actions";
import Tiktok from "static/images/tiktok.svg";

class footer extends Component {
  render() {
    const footerData1 = FooterData.slice(1, 5);
    const footerData2 = FooterData.slice(5);
    window.onresize = () => {
      this.props.updateWindowWidth();
    };
    return (
      <div className=" component-footer pt-10 pb-3 px-5 lg:pt-20 lg:pb-8 lg:px-10 text-white font-bold ">
        <div className="inner-container">
          <div className="lg:flex">
            <img
              src={UAEMirchiLogo}
              className="footer-logo lg:h-24 lg:mr-48 lg:mt-5"
              alt="footer-logo"
            />
            <div className="footer-text-container font-bold flex flex-col lg:flex-row justify-start items-start  mt-6 mb-8 text-white text-lg lg:text-sm lg:mt-0 mb:0 lg:mr-16">
              <div className="flex flex-col lg:mr-16">
                {footerData1.map((data, i) => {
                  return (
                    <Link className="footer-text font-bold" key={i} to={data.link} style={{color:"white"}}>
                      {data.name}
                    </Link>
                  );
                })}
              <div className="flex flex-col lg:mr-16">
                {footerData2.map((data, i) => {
                  return (
                    <Link className="footer-text font-bold" key={i} to={data.link}style={{color:"white"}}>
                      {data.name}
                    </Link>
                  );
                })}
              </div>
              </div>
              <div className="flex flex-col">
                <div></div>
              </div>
            </div>
            <div className="hidden-desktop">
              <PlayStoreIcons tablet />
            </div>
            <div className="social-icons flex justify-start  lg:mr-16  lg:flex-col lg:items-start">
              <div className="footer-text text-white  mb-2 hidden lg:block">
                Follow Us
            </div>
              <div className="flex ">
                <a
                  href="https://www.facebook.com/mirchiuae"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="relative"
                >
                  <img
                    src={FacebookColored}
                    alt="facebook"
                    className="h-8 w-8 mr-5"
                  />
                </a>
                <a
                  href="https://www.instagram.com/mirchi_uae/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="relative"
                >
                  <img
                    src={InstagramColored}
                    alt="instagram"
                    className="h-8 w-8 mr-5"
                  />
                </a>
                <a
                  href="https://www.twitter.com/mirchiuae"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="relative"
                >
                  <img
                    src={TwitterColored}
                    alt="instagram"
                    className="h-8 w-8 mr-5"
                  />
                </a>
                <a
                      href="https://vm.tiktok.com/ZSeJGqFSQ/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="relative"
                    >
                      <img
                        src={Tiktok}
                        alt="tiktok"
                        className="h-8 w-8 mr-5"
                      />
                    </a>
              </div>
            </div>
            <div className="hidden-tablet">
              <PlayStoreIcons />
            </div>
          </div>
          <div className="lg:mx-auto lg:text-center">
            <div className="privacy-policy-text text-11 mt-3">
            {/* Privacy Policy • Terms & Conditions */}
              Owned by Dolphin Recording Studio LLC 
          </div>
            <div className="copyright-text text-11 mt-2 font-light">
              Copyright 2018 © Entertainment Network (India) Limited. All Rights
              Reserved
          </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(
  ({ ui }) => ({ ...ui }),
  dispatch => bindActionCreators({ ...uiActions }, dispatch)
)(footer);
