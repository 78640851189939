import React, { useState } from "react";
import "./contest.scss";
import axios from "axios";
import { useEffect } from "react";
import CardsHeader from "components/cardsHeader";
import { Link } from "react-router-dom";
import { API_URL } from "../../services/config/apiUrl";
import RedTagMobo from "../../static/images/RedTag/RedtagMobo.png";

import * as ContestsState from "../../store/contest/action";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { validS3Url } from "../../services/config/request";

class Contest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contests: [],
      loading: true,
    };
  }
  async componentWillMount() {
    await this.props.getContestListRequest().then((res) => {
      this.setState({ contests: res });
    });
  }

  render() {
// console.log(this.props);
    // Contest shedule checks whethere the contest in ongoing 
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = dd + "/" + mm + "/" + yyyy;
    function contestvalidation(Date1, Date2) {
      let dateConv1 = new Date(Date1);
      let dateConv2 = new Date(Date2);

      let sDate = `${dateConv1.getDate()}/${
        dateConv1.getMonth() + 1
      }/${dateConv1.getFullYear()}`;

      let eDate = `${dateConv2.getDate()}/${
        dateConv2.getMonth() + 1
      }/${dateConv2.getFullYear()}`;

  
      var from = sDate.split("/");
      var to = eDate.split("/");
      var check = today.split("/");

      var fromDate = new Date(from[2], parseInt(from[1])-1, from[0]);  // -1 because months are from 0 to 11
      var toDate   = new Date(to[2], parseInt(to[1])-1, to[0]);
      var checkDate = new Date(check[2], parseInt(check[1])-1, check[0]);

      return (checkDate <= toDate && checkDate >= fromDate) ? true : false;
    }

    return (
      <div className="wrapper contests pt-24 lg:pt-10">
        <CardsHeader heading="Ongoing Contests" headtype="h1"></CardsHeader>
        <div className="contests-list">
          {this.state.contests.map((contest) => {
            // console.log(contestvalidation(contest.startDate, contest.endDate));
            if(contestvalidation(contest.startDate, contest.endDate)){
            return (
              <div style={{marginRight: '24px'}}>
                <Link
                  to={`/contest/${contest.slug}`}
                  // params={{ contestTitle: contest.id }}
                >
                  <div
                    className="flex-items"
                    style={{
                      backgroundImage: validS3Url(`url(${contest.banner})`),
                      // backgroundImage: `url(https://radiomirchiuae.s3.amazonaws.com/RedtagMobo.png)`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                  <div
                    style={{
                      marginTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    <h3 style={{ textAlign: "center", color: "#1b3685" }}>
                      {contest.title}
                    </h3>
                  </div>
                </Link>
              </div>
            );
          }
          })}
        </div>
      </div>
    );
  }
}

export default connect(
  ({ contest }) => ({ ...contest }),
  (dispatch) => bindActionCreators({ ...ContestsState }, dispatch)
)(Contest);
