import { Api } from "./config/request";

export const requestMirchiMurgaData = async () => {
    try {
    return Api.getRequest("mirchiMurga/");
    } catch (err) {
      return err;
    }
  
};

export const MirchiMurga = {
    requestMirchiMurgaData
};
